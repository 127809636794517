//  dependencies
import initialState from "./initial-state";
import state from "./state";

export type payload = Partial<state> & {}

// reducer
const reducer = (state: state, payload: payload): state => {
    try {

        const newStateValues: state = { ...state, ...payload }
        const stateKeys = Object.getOwnPropertyNames(payload)
        const type = stateKeys[0]

        // checking wether reducer action types contains "Error" keyword so that to change disabled state to true
        if (type.includes("Error"))
            // copy the previous state then update only the received keyword and set disabled to true
            return { ...newStateValues, disabled: true }

        // checking for action that are'nt allowed to change disabled state
        if ((type === "authenticated") || (type === "disabled") || (type === "loading"))
            // copy the previous state then update only the received keywords
            return newStateValues

        if ((type === "unMount"))
            // return initial state
            return { ...initialState, authenticated: state.authenticated ? true : false, notification: state.notification, theme: state.theme }

        // copy the previous state then update only the received keywords, set disabled to true and update "Error" field with empty string
        return { ...newStateValues, [`${type}Error`]: "", disabled: false }

    } catch (error) {
        console.log(`Reducer error ${(error as Error).message}`)
        return state
    }
}

// exporting
export default reducer