/* dependencies */
import { isAdmin, isOwner, isUser } from "."
import { menu } from "../components/sidebar"
import { can } from "./permissions"

/* menus */
const menus: menu[] = [

    // dashboard
    {
        title: "Dashboard",
        link: "/dashboard",
        icon: "dashboard",
        visible: can("view_dashboard_menu")
    },

    // room
    {
        title: "room",
        link: "#",
        icon: "meeting_room",
        visible: (can("view_room_menu") || can("view_room_lease_menu")) && (isOwner || isUser),
        hasSubMenu: true,
        subMenu: [
            {
                title: "new room",
                link: "/room/form",
                visible: can("create_room")
            },
            {
                title: "list rooms",
                link: "/room/list",
                visible: can("list_room")
            },
            {
                title: "new lease",
                link: "/room/lease-form",
                visible: can("create_room_lease")
            },
            {
                title: "list leases",
                link: "/room/lease-list",
                visible: can("list_room_lease")
            },
        ]
    },

    // tenant
    {
        title: "tenant",
        link: "#",
        icon: "groups",
        visible: can("view_tenant_menu") && (isOwner || isUser),
        hasSubMenu: true,
        subMenu: [
            {
                title: "new tenant",
                link: "/tenant/form",
                visible: can("create_tenant")
            },
            {
                title: "list tenants",
                link: "/tenant/list",
                visible: can("list_tenant")
            }
        ]
    },

    // expense
    {
        title: "expense",
        link: "#",
        icon: "price_change",
        visible: can("view_expense_menu") && (isOwner || isUser),
        hasSubMenu: true,
        subMenu: [
            {
                title: "new expense",
                link: "/expense/form",
                visible: can("create_expense")
            },
            {
                title: "list expenses",
                link: "/expense/list",
                visible: can("list_expense")
            },
            {
                title: "new expense type",
                link: "/expense/type-form",
                visible: can("create_expense_type")
            },
            {
                title: "list expense types",
                link: "/expense/type-list",
                visible: can("list_expense_type")
            },
        ]
    },

    // message
    {
        title: "message",
        link: isAdmin ? "/message/form" : "#",
        icon: "sms",
        visible: can("view_message_menu"),
        hasSubMenu: isAdmin ? false : true,
        subMenu: [
            {
                title: "new message",
                link: "/message/form",
                visible: can("create_message")
            },
            {
                title: "list messages",
                link: "/message/list",
                visible: can("list_message")
            }
        ]
    },

    // report
    {
        title: "report",
        link: "#",
        icon: "receipt_long",
        visible: can("view_report_menu"),
        hasSubMenu: true,
        subMenu: [
            {
                title: "new report",
                link: "/report/form",
                visible: can("create_report")
            },
            {
                title: "income statement",
                link: "/report/income-statement",
                visible: can("view_income_statement")
            }
        ]
    },

    // user
    {
        title: "user",
        link: "#",
        icon: "group",
        visible: can("view_user_menu"),
        hasSubMenu: true,
        subMenu: [
            {
                title: "new user",
                link: "/user/form",
                visible: can("create_user")
            },
            {
                title: "list users",
                link: "/user/list",
                visible: can("list_user")
            }
        ]
    },

    // role (nb: contradct on visible btn "view_role_menu" or "create_role")
    {
        title: "role",
        link: "#",
        icon: "task",
        visible: can("view_role_menu"),
        hasSubMenu: true,
        subMenu: [
            {
                title: "new role",
                link: "/role/form",
                visible: can("create_role")
            },
            {
                title: "list roles",
                link: "/role/list",
                visible: can("list_role")
            }
        ]
    },

    // payment
    {
        title: "payment",
        link: "/payment/list",
        icon: "payment",
        visible: can("list_payment") && can("view_payment_menu"),
        hasSubMenu: false
    },

    // house
    {
        title: "house",
        link: "#",
        icon: "apartment",
        visible: can("view_house_menu"),
        hasSubMenu: true,
        subMenu: [
            {
                title: "new house",
                link: "/house/form",
                visible: can("create_house")
            },
            {
                title: "list houses",
                link: "/house/list",
                visible: can("list_house")
            },
            {
                title: "house data",
                link: "/house/data",
                visible: can("view_house_data") || isAdmin
            },
            {
                title: "change house settings",
                link: "/house/settings",
                visible: can("change_house_settings") && (isOwner || isUser)
            }
        ]
    },

    // general
    {
        title: "profile",
        link: "#",
        icon: "settings",
        visible: true,
        hasSubMenu: true,
        subMenu: [
            {
                title: "view profile",
                link: "/profile/view",
                visible: true
            },
            {
                title: "edit profile",
                link: "/profile/edit",
                visible: true
            },
            {
                title: "change password",
                link: "/profile/password",
                visible: true
            }
        ]
    }
]

/* exporting menus */
export default menus