/* requiring dependencies */
import React from "react"

/* icon */
export type icon = {
    type: "round" | "outlined" | "sharp"
    name: string
    position?: "right" | "left" | "prefix"
    rotate?: boolean
}

/* icon */
export const Icon: React.FunctionComponent<icon> = React.memo((props: icon) => (
    <i className={`material-icons-${props.type} hide-on-print ${props.position} ${props.rotate ? "rotate" : ""} `}>{props.name}</i>
))

