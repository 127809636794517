// swahili words
const swahiliWords: string[] = [
    "dashibodi",
    "chumba",
    "chumba kipya",
    "mkataba mpya",
    "orodhesha vyumba",
    "orodhesha mikataba",
    "mpangaji",
    "mpangaji mpya",
    "orodhesha wapangaji",
    "matumizi",
    "matumizi mpya",
    "orodhesha matumizi",
    "aina mpya ya matumizi",
    "orodhesha aina za matumizi",
    "mtumiaji",
    "mtumiaji mpya",
    "orodhesha watumiaji",
    "jukumu",
    "jukumu jipya",
    "orodhesha majukumu",
    "malipo",
    "nyumba",
    "nyumba",
    "nyumba mpya",
    "taarifa za nyumba",
    "orodhesha nyumba",
    "badilisha mipangilio ya nyumba",
    "wasifu",
    "tazama wasifu",
    "hariri wasifu",
    "badilisha neno la siri",
    "ingia",
    "ondoka",
    "Lo! ukurasa haujapatikana",
    "ukurasa haujapatikana",
    "Samahani, lakini ukurasa unaotafuta haupatikani. tafadhali, hakikisha umeandika url sahihi na una ruhusa ya kutazama hii rasilimali",
    "tengeneza",
    "inahitajika",
    "jina",
    "ingiza jina",
    "mtumiaji (hiari)",
    "ingiza jina la mtumiaji",
    "namba ya chumba",
    "ingiza namba ya chumba",
    "aina",
    "chagua aina",
    "bei ya kila mwezi",
    "ingiza bei ya kila mwezi",
    "hali",
    "inapatikana",
    "imepangishwa",
    "maelezo",
    "ingiza maelezo",
    "tarehe ya kuingia",
    "Kipindi (Miezi)",
    "tarehe ya kutoka",
    "tarehe",
    "jumla ya kiasi",
    "kiasi kilicholipwa",
    "ingiza kiasi kilicholipwa",
    "orodhesha iliyopangishwa",
    "orodhesha iliyofutwa",
    "orodhesha inayopatikana",
    "mpangilio unaosonga juu",
    "mpangilio unaosonga chini",
    "muda wa uundaji",
    "kikomo cha",
    "kikomo kwa",
    "ingiza neno kuu la utafutaji",
    "chagua yote",
    "chagua",
    "chaguo",
    "jumla",
    "hariri",
    "angalia",
    "hariri chumba",
    "rekebisha",
    "taarifa za chumba",
    "orodhesha mkataba",
    "orodhesha mikataba",
    "imetengenezwa na",
    "wakati iliyotengenezwa",
    "imerekebishwa na",
    "wakati iliyorekebishwa",
    "imefutwa",
    "imefutwa na",
    "wakati iliyofutwa",
    "futa chumba",
    "rejesha",
    "imefanikiwa kurekebishwa",
    "hai",
    "imemalizika",
    "orodha hai",
    "orodha iliyoisha",
    "gharama",
    "chapisha",
    "mkataba mpya",
    "hariri mkataba",
    "taarifa za mkataba",
    "namba ya simu",
    "ingiza namba ya simu",
    "mkoa",
    "chagua mkoa",
    "mahali",
    "ingiza mahali",
    "mtaa",
    "ingiza mtaa",
    "hariri mpangaji",
    "taarifa za mpangaji",
    "aina ya matumizi",
    "ingiza aina ya matumizi",
    "orodha ya leo",
    "orodhesha iliyolipwa",
    "orodhesha isiyolipwa",
    "orodhesha iliyolipwa kidogo",
    "kiasi kilichobaki",
    "hariri matumizi",
    "taarifa za matumizi",
    "hariri aina ya matumizi",
    "taarifa za aina ya matumizi",
    "aina ya akaunti",
    "lugha",
    "jina la mtumiaji",
    "watumiaji",
    "orodhesha wamiliki",
    "orodhesha maadmini",
    "orodhesha wafanyakazi",
    "orodhesha wasaidizi",
    "hapana",
    "ndiyo",
    "hariri mtumiaji",
    "taarifa za mtumiaji",
    "ruhusa",
    "Tazama dashibodi",
    "Tazama menyu ya dashibodi",
    "Tazama matumizi",
    "Orodhesha matumizi",
    "Tengeneza matumizi",
    "Futa matumizi",
    "Tazama menyu ya matumizi",
    "Tazama aina ya matumizi",
    "Orodhesha aina ya matumizi",
    "Tengeneza aina ya matumizi",
    "Futa aina ya matumizi",
    "Rejesha iliyofutwa",
    "kwa ujumla",
    "Tazama nyumba",
    "Hariri nyumba",
    "Orodhesha nyumba",
    "Rekebisha nyumba",
    "Tengeneza nyumba",
    "Futa nyumba",
    "Badilisha nyumba",
    "Tazama menyu ya nyumba",
    "Tazama taarifa za nyumba",
    "Badilisha mipangilio ya nyumba",
    "Tazama malipo",
    "Hariri malipo",
    "Orodhesha malipo",
    "Futa malipo",
    "Ghairi malipo",
    "Tazama menyu ya malipo",
    "Tazama jukumu",
    "Hariri jukumu",
    "Orodhesha jukumu",
    "Tengeneza jukumu",
    "Futa jukumu",
    "Tazama menyu ya jukumu",
    "Tazama chumba",
    "Orodhesha vyumba",
    "Tengeneza chumba",
    "Tazama menyu ya chumba",
    "mkataba",
    "Tazama mkataba",
    "Hariri mkataba",
    "Chapisha mkataba",
    "Tengeneza mkataba",
    "Futa mkataba",
    "Tazama menyu ya mkataba",
    "Tazama mpangaji",
    "Orodhesha wapangaji",
    "Tengeneza mpangaji",
    "Futa mpangaji",
    "Tazama menyu ya mpangaji",
    "Tazama mtumiaji",
    "Orodhesha watumiaji",
    "Tengeneza mtumiaji",
    "Tazama menyu ya mtumiaji",
    "Taarifa ya jukumu",
    "Tengeneza malipo",
    "orodha nyingine",
    "Orodhesha malipo",
    "Orodhesha yaliyoghairiwa",
    "Orodhesha malipo ya server",
    "Orodhesha malipo ya ufungaji wa mfumo",
    "Orodhesha malipo ya mwezi",
    "Taarifa za malipo",
    "kataa",
    "namba ya nyumba",
    "ingiza namba ya nyumba",
    "mmiliki wa nyumba",
    "ingiza mmiliki wa nyumba",
    "ada ya kila mwezi (Hiari)",
    "ingiza ada",
    "siku",
    "ingiza siku",
    "orodha iliyolipa",
    "orodha isiyolipa",
    "ada ya kila mwezi",
    "taarifa za nyumba",
    "ingia katika nyumba hii",
    "toka katika nyumba hii",
    "ripoti",
    "ripoti mpya",
    "tengeneza ripoti",
    "taarifa ya mapato",
    "taarifa mpya ya mapato",
    "mapato ya jumla",
    "chapisha ripoti",
    "pakua ripoti",
    "tazama menyu ya ripoti",
    "tazama taarifa ya mapato",
    "tazama takwimu za ripoti",
    "tazama ripoti ya data iliyofutwa",
    "kataa",
    "kubali",
    "imefanikiwa kutengenezwa",
    "imefanikiwa kurekebishwa",
    "hakuna chumba kilichopatikana",
    "uthibitisho wa chumba",
    "una uhakika unataka kufuta chumba hiki?",
    "una uhakika unataka kufuta vyumba hivi?",
    "una uhakika unataka kurejesha chumba hiki?",
    "una uhakika unataka kurejesha vyumba hivi?",
    "chumba kimefutwa",
    "vyumba vimefutwa",
    "chumba kimerudishwa",
    "vyumba vimerudishwa",
    "haiwezi kuwa chini au sawa na sifuri",
    "haiwezi kuwa chini kuliko jumla ya kiasi",
    "hakuna mkataba uliopatikana",
    "uthibitisho wa mkataba",
    "una uhakika unataka kufuta mkataba huu?",
    "una uhakika unataka kufuta mikataba hii?",
    "una uhakika unataka kurejesha mkataba huu?",
    "una uhakika unataka kurejesha mikataba hii?",
    "mkataba umefutwa",
    "mikataba imefutwa",
    "mkataba umerejeshwa",
    "mikataba imerejeshwa",
    "hakuna mpangaji aliyepatikana",
    "uthibitisho wa mpangaji",
    "una uhakika unataka kufuta mpangaji huyu?",
    "una uhakika unataka kufuta wapangaji hawa?",
    "una uhakika unataka kurejesha mpangaji huyu?",
    "una uhakika unataka kurejesha wapangaji hawa?",
    "mpangaji amefutwa",
    "wapangaji wamefutwa",
    "mpangaji amerejeshwa",
    "wapangaji wamerejeshwa",
    "hakuna matumizi yaliyopatikana",
    "uthibitisho wa matumizi",
    "una uhakika unataka kufuta matumizi haya?",
    "una uhakika unataka kufuta matumizi haya?",
    "una uhakika unataka kurejesha matumizi haya?",
    "una uhakika unataka kurejesha matumizi haya?",
    "matumizi yamefutwa",
    "matumizi yamefutwa",
    "matumizi yamerejeshwa",
    "matumizi yamerejeshwa",
    "hakuna aina ya matumizi yaliyopatikana",
    "uthibitisho wa aina ya matumizi",
    "una uhakika unataka kufuta aina hii ya matumizi?",
    "una uhakika unataka kufuta aina hii ya matumizi?",
    "una uhakika unataka kurejesha aina hii ya matumizi?",
    "una uhakika unataka kurejesha aina hii ya matumizi?",
    "matumizi yamerejeshwa",
    "matumizi yamefutwa",
    "aina za matumizi zimerejeshwa",
    "aina za matumizi zimefutwa",
    "hakuna mtumiaji aliyepatikana",
    "uthibitisho wa mtumiaji",
    "una uhakika unataka kufuta mtumiaji huyu?",
    "una uhakika unataka kufuta watumiaji hawa?",
    "una uhakika unataka kurejesha mtumiaji huyu?",
    "una uhakika unataka kurejesha watumiaji hawa?",
    "mtumiaji amefutwa",
    "watumiaji wamefutwa",
    "mtumiaji amerejeshwa",
    "watumiaji wamerejeshwa",
    "hakuna jukumu lililopatikana",
    "uthibitisho wa jukumu",
    "una uhakika unataka kufuta jukumu hili?",
    "una uhakika unataka kufuta majukumu haya?",
    "una uhakika unataka kurejesha jukumu hili?",
    "una uhakika unataka kurejesha majukumu haya?",
    "jukumu limefutwa",
    "majukumu yamefutwa",
    "jukumu limerejeshwa",
    "majukumu yamerejeshwa",
    "uthibitisho wa malipo",
    "una uhakika unataka kukataa malipo haya?",
    "una uhakika unataka kukataa malipo haya?",
    "malipo yamekataliwa",
    "malipo yamekataliwa",
    "malipo ya mwezi yanahitajika",
    "hakuna nyumba iliyopatikana",
    "uthibitisho wa nyumba",
    "una uhakika unataka kufuta nyumba hii?",
    "una uhakika unataka kufuta nyumba hizi?",
    "una uhakika unataka kurejesha nyumba hii?",
    "una uhakika unataka kurejesha nyumba hizi?",
    "nyumba imefutwa",
    "nyumba zimefutwa",
    "nyumba imerejeshwa",
    "nyumba zimerejeshwa",
    "ripoti za gharama",
    "ripoti ya matumizi",
    "ripoti ya mikataba",
    "ripoti ya vyumba",
    "ripoti ya wapangaji",
    "ripoti ya matumizi yaliyofutwa",
    "ripoti ya malipo yaliyofutwa",
    "ripoti ya mikataba iliyofutwa",
    "ripoti ya vyumba vilivyofutwa",
    "ripoti ya wapangaji waliofutwa",
    "neno la siri la zamani",
    "ingiza neno la siri la zamani",
    "neno la siri la zamani ni lazima liwe na angalau herufi 8",
    "neno la siri jipya ni lazima liwe na angalau herufi 8",
    "uthibitishaji wa neno la siri ni lazima uwe na herufi angalau 8",
    "neno la siri jipya",
    "uthibitisho wa neno la siri jipya",
    "neno la siri halilingani",
    "ingiza neno la siri jipya",
    "neno la siri la zamani si sahihi",
    "karibu tena!",
    "akaunti",
    "andika jina la mtumiaji au nambari ya simu",
    "neno la siri",
    "ingiza neno la siri",
    "umesahu neno la siri?",
    "akaunti haipo",
    "neno la siri sio sahihi",
    "neno la siri ni lazima liwe na angalau herufi 4",
    "wasiliana nasi",
    "tayari una akaunti",
    "tuma nambari",
    "imeshindwa kutuma nambari ya kuthibitisha",
    "umesahau neno la siri",
    "nambari ya kuthibitisha imetumwa kwa nambari yako ya simu",
    "nambari ya uthibitishaji",
    "nambari ya uthibitisho",
    "ingiza nambari iliyotumwa kwa nambari yako ya simu",
    "thibitisha",
    "nambari ya uthibitisho ni lazima iwe na tarakimu 6",
    "nambari ya uthibitisho sio sahihi",
    "mlinganisho wa data",
    "muhtasari",
    "sekunde chache zilizopita",
    "dakika 1 imepita",
    "dakika 2 zilizopita",
    "dakika 4 zilizopita",
    "dakika 5 zilizopita",
    "dakika 6 zilizopita",
    "dakika 7 zilizopita",
    "dakika 8 zilizopita",
    "dakika 9 zilizopita",
    "dakika 10 zilizopita",
    "dakika 11 zilizopita",
    "dakika 12 zilizopita",
    "dakika 13 zilizopita",
    "dakika 14 zilizopita",
    "dakika 15 zilizopita",
    "dakika 16 zilizopita",
    "dakika 17 zilizopita",
    "dakika 3 zilizopita",
    "dakika 18 zilizopita",
    "dakika 19 zilizopita",
    "dakika 20 zilizopita",
    "dakika 21 zilizopita",
    "dakika 22 zilizopita",
    "dakika 23 zilizopita",
    "dakika 24 zilizopita",
    "dakika 25 zilizopita",
    "dakika 26 zilizopita",
    "dakika 27 zilizopita",
    "dakika 28 zilizopita",
    "dakika 29 zilizopita",
    "dakika 30 zilizopita",
    "dakika 31 zilizopita",
    "dakika 32 zilizopita",
    "dakika 33 zilizopita",
    "dakika 34 zilizopita",
    "dakika 35 zilizopita",
    "dakika 36 zilizopita",
    "dakika 37 zilizopita",
    "dakika 38 zilizopita",
    "dakika 39 zilizopita",
    "dakika 40 zilizopita",
    "dakika 41 zilizopita",
    "dakika 42 zilizopita",
    "dakika 43 zilizopita",
    "dakika 44 zilizopita",
    "dakika 45 zilizopita",
    "dakika 46 zilizopita",
    "dakika 47 zilizopita",
    "dakika 48 zilizopita",
    "dakika 49 zilizopita",
    "dakika 50 zilizopita",
    "dakika 51 zilizopita",
    "dakika 52 zilizopita",
    "dakika 53 zilizopita",
    "dakika 54 zilizopita",
    "dakika 55 zilizopita",
    "dakika 56 zilizopita",
    "dakika 57 zilizopita",
    "dakika 58 zilizopita",
    "dakika 59 zilizopita",
    "saa moja lililopita",
    "masaa 2 yaliyopita",
    "masaa 3 yaliyopita",
    "masaa 4 yaliyopita",
    "masaa 5 yaliyopita",
    "masaa 6 yaliyopita",
    "masaa 7 yaliyopita",
    "masaa 8 yaliyopita",
    "masaa 9 yaliyopita",
    "masaa 10 yaliyopita",
    "masaa 11 yaliyopita",
    "masaa 12 yaliyopita",
    "masaa 13 yaliyopita",
    "masaa 14 yaliyopita",
    "masaa 15 yaliyopita",
    "masaa 16 yaliyopita",
    "masaa 17 yaliyopita",
    "masaa 18 yaliyopita",
    "masaa 19 yaliyopita",
    "masaa 20 yaliyopita",
    "masaa 21 yaliyopita",
    "masaa 22 yaliyopita",
    "masaa 23 yaliyopita",
    "siku moja imepita",
    "siku 2 zilizopita",
    "siku 3 zilizopita",
    "siku 4 zilizopita",
    "siku 5 zilizopita",
    "siku 6 zilizopita",
    "siku 7 zilizopita",
    "siku 8 zilizopita",
    "siku 9 zilizopita",
    "siku 10 zilizopita",
    "siku 11 zilizopita",
    "siku 12 zilizopita",
    "siku 13 zilizopita",
    "siku 14 zilizopita",
    "siku 15 zilizopita",
    "siku 16 zilizopita",
    "siku 17 zilizopita",
    "siku 18 zilizopita",
    "siku 19 zilizopita",
    "siku 20 zilizopita",
    "siku 21 zilizopita",
    "siku 22 zilizopita",
    "siku 23 zilizopita",
    "siku 24 zilizopita",
    "siku 25 zilizopita",
    "siku 26 zilizopita",
    "siku 27 zilizopita",
    "siku 28 zilizopita",
    "siku 29 zilizopita",
    "siku 30 zilizopita",
    "mwezi 1 uliopita",
    "miezi 2 iliyopita",
    "miezi 3 iliyopita",
    "miezi 4 iliyopita",
    "miezi 5 iliyopita",
    "miezi 6 iliyopita",
    "miezi 7 iliyopita",
    "miezi 8 iliyopita",
    "miezi 9 iliyopita",
    "miezi 10 iliyopita",
    "miezi 11 iliyopita",
    "miezi 12 iliyopita",
    "mwaka 1 uliopita",
    "miaka 2 iliopita",
    "miaka 3 iliopita",
    "miaka 4 iliopita",
    "miaka 5 iliopita",
    "miaka 6 iliopita",
    "miaka 7 iliopita",
    "miaka 8 iliopita",
    "miaka 9 iliopita",
    "miaka 10 iliopita",
    "data mpya ilitengenezwa",
    "data ilirekebishwa",
    "data ilifutwa kwa muda",
    "data ilifutwa milele",
    "kweli",
    "januari",
    "februari",
    "machi",
    "aprili",
    "mei",
    "juni",
    "julai",
    "agosti",
    "septemba",
    "oktoba",
    "novemba",
    "desemba",
    "malipo ya seva",
    "haipo",
    "njia za malipo",
    "kingereza",
    "lugha",
    "visaidizi",
    "",
    "",
    "",
    "matumizi yasiyolipwa",
    "kiswahili",
    "wapangaji",
    "vyumba",
    "mikataba",
    "mkataba",
    "mikataba",
    "mkataba",
    "matumizi",
    "malipo",
    "vyumba vilivyopo",
    "vyumba vilivyopangishwa",
    "mikataba iliyoisha",
    "mikataba hai",
    "takwimu",
    "takwimu za matumizi",
    "takwimu za malipo",
    "takwimu za mikataba",
    "takwimu za mikataba",
    "takwimu za vyumba",
    "takwimu za wapangaji",
    "futa",
    "aina ya ripoti",
    "sahihi ya msimamizi / mmiliki wa nyumba",
    "shahidi (jina na sahihi)",
    "sahihi ya mpangaji",
    "mkataba wa upangishaji wa nyumba/apartment/chumba/frame",
    "masharti",
    "malipo ya benki",
    "malipo ya simu",
]

// exporting
export default swahiliWords