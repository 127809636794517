/* dependencies */
import React, { Suspense } from 'react'
import Application from './helpers/application'
import { application } from './types'
import { BrowserRouter as Router } from "react-router-dom"
import routing from './routes/routing'
import { AuthLayout, GuestLayout } from './components/layout'
import Loader from './components/loader'
import reducer from './hooks/reducer'
import initialState from './hooks/initial-state'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import Dialog from './components/dialog'
import { text } from './helpers'

/* create a memorized application FC */
const App: React.FunctionComponent = React.memo(() => {

  // state management
  const [state, dispatch] = React.useReducer(reducer, initialState)
  const application: application = new Application(state, dispatch)
  const currentHour = new Date().getHours()
  const theme = application.state.theme !== "auto" ? `${application.state.theme}` : ((currentHour >= 18) || (currentHour <= 6)) ? "dark" : "light"
  const dark = "#1B1B1B"
  const light = "F0F0F9"

  const history = window.history

  React.useEffect(() => {
    const handlePopstate = (event: any) => {
      // Check if can go back or forward
      if (event.state) {
        // Go back or forward to the previous/next state in History
        history.go(event.state.delta)
      }
    }

    window.addEventListener('popstate', handlePopstate)

    return () => {
      window.removeEventListener('popstate', handlePopstate)
    }
  }, [history])

  // remove notification after five seconds
  React.useEffect(() => {

    application.retrieveUserAndAuthenticate()
    if (state.notification) {
      setTimeout(() => {
        dispatch({ notification: "" })
      }, 3000)
    }

    // eslint-disable-next-line
  }, [state.notification])

  // returning application component view
  return (
    <HelmetProvider>
      <Helmet>
        <link rel="stylesheet" href={`/${theme}.css`} />
        <meta name="theme-color" content={theme === "light" ? light : dark} />
        <meta name="msapplication-TileColor" content={theme === "light" ? light : dark} />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color={theme === "light" ? light : dark} />
      </Helmet>
      <Suspense fallback={<Loader />}>
        {state.loading ? <Loader /> : null}
        <Router>
          {
            state.authenticated
              ?
              <AuthLayout
                authenticate={application.authenticate}
                closeSidebar={application.closeSidebar}
                toggleSidebar={application.toggleSidebar}
                unMount={application.unMount}
                message={state.notification}
                user={application.user}
              >
                {routing(application)}
              </AuthLayout>
              :
              <GuestLayout message={state.notification}>
                {routing(application)}
              </GuestLayout>
          }
          <Dialog
            title={`${text.reFormat(state.schema)} confirmation`}
            text={`Are you sure you want to ${state.backendStatus === "restored" ? "restore" : state.backendStatus === "canceled" ? "cancel" : state.backendStatus === "completed" ? "compelete" : "delete"} ${state.ids.length > 1 ? `these ${text.reFormat(state.collection)}` : `this ${text.reFormat(state.schema)}`}?`}
            action={application.updateBackendStatus}
            toggleDialog={application.toggleComponent}
          />
        </Router>
      </Suspense>
    </HelmetProvider>
  )
})

/* exporting application component */
export default App