/* dependencies */
import React from "react"
import { Icon } from "./elements"
import Breadcrumb from "./breadcrumb"
import { text, applicationName } from "../helpers"
import menus from "../helpers/menu"
import translate from "../helpers/translator"
import { auth } from "./layout"
import { Link } from "react-router-dom"

/* menu */
export type menu = {
    title: string
    link: string
    icon: string
    visible: boolean
    subMenu?: subMenu[]
    hasSubMenu?: boolean
}

/* sub menu */
export type subMenu = {
    title: string
    link: string
    visible: boolean
}

/* sidebar */
const Sidebar: React.FunctionComponent<auth> = React.memo((props: auth) => {
    const [activeLink, setActiveLink] = React.useState<string>(window.location.pathname)
    const [activMenu, setActiveMenu] = React.useState<number>(-1)
    const [icon, setIcon] = React.useState<string>("dashboard")
    const [title, setTitle] = React.useState<string>("dashboard")

    window.onclick = (event: any): void => {
        // getting current pathname
        const currentPathname: string = window.location.pathname

        // changing active nav link
        setActiveLink(currentPathname)

        // update breadcrumb
        updateBreadcrumb()

        // close active sidebar content
        if ((event.target.tagName !== "A") && (event.target.tagName !== "SPAN") && (event.target.tagName !== "I"))
            setActiveMenu(-1)

    }

    // update breadcrumb
    function updateBreadcrumb(): void {
        const currentPathname: string = window.location.pathname
        // eslint-disable-next-line
        menus.map((menu: menu) => {
            if (currentPathname.includes(menu.title.replace(/ /g, "-").toLowerCase())) {
                setTitle(menu.title)
                setIcon(menu.icon)
            }
        })
    }

    React.useEffect(() => {
        updateBreadcrumb()
        // eslint-disable-next-line
    }, [])


    // function for toggling sidebar content
    const toggleSidebarContent = (index: number): void => {
        // toggle sibar nav content
        if (index === activMenu)
            setActiveMenu(-1)
        else
            setActiveMenu(index)
    }

    // function for rendering menu
    const renderMenu = React.useCallback(() => {
        return menus.map((menu: menu, index: number) => {
            if (menu.visible)
                return (
                    <li className="nav-item" key={index} onClick={() => {
                        toggleSidebarContent(index)
                        if (!menu.hasSubMenu) {
                            props.unMount()
                            props.closeSidebar()
                        }
                    }}>
                        <Link
                            to={menu.link}
                            className={`nav-link ${activeLink.includes(`/${menu.title.toLowerCase()}/`) ? "active" : ""}`}
                        >
                            <i className={`material-icons-round`}>{menu.icon}</i>
                            <span>{translate(menu.title)}</span>
                        </Link>
                        {
                            menu.hasSubMenu && menu.subMenu
                                ?
                                <ul className={`nav-content ${activMenu === index ? "show" : ""}`}>
                                    {
                                        menu.subMenu.sort((a, b) => {
                                            if (a.title.length < b.title.length) {
                                                return -1;
                                            }
                                            if (a.title.length > b.title.length) {
                                                return 1;
                                            }
                                            return 0;
                                        }
                                        ).map((subMenu: subMenu, indexTwo: number) => {
                                            if (subMenu.visible)
                                                return (
                                                    <li key={indexTwo}>
                                                        <Link to={subMenu.link} className={`${activeLink.includes(subMenu.link) ? "active" : ""}`} onClick={() => {
                                                            props.unMount()
                                                            props.closeSidebar()
                                                        }}>
                                                            <Icon name="chevron_right" type="round" />
                                                            <span>{translate(subMenu.title)}</span>
                                                        </Link>
                                                    </li>
                                                )
                                            else
                                                return null
                                        })
                                    }
                                </ul>
                                : null
                        }
                    </li>
                )
            else
                return null
        })
        // eslint-disable-next-line
    }, [activeLink, activMenu])

    /* const changeLanguage = async (): Promise<void> => {
        try {
            let language: "english" | "swahili" = activeLanguage === "swahili" ? "english" :
                activeLanguage === "english" ? "swahili" : "english"
            storage.store("language", language)
            setActiveLanguage(language)
            const user = getInfo("user")
            const body = {
                schema: "user",
                condition: { _id: user._id },
                newDocumentData: {
                    $set: {
                        updated_by: user._id,
                        settings: {
                            ...user.settings,
                            language,
                        }
                    }
                }
            }
            let response: any = await (await fetch(`${serverURL}/vsl/update-document`, {
                mode: "cors",
                method: "PUT",
                body: JSON.stringify(body),
                headers: {
                    "Content-Type": "application/json"
                }
            })).json()

            if (response.success) {
                storage.store("user", response.message)
                window.location.reload()
            }

        } catch (error) {
            console.log(`Language change error: ${(error as Error).message}`)
        }
    } */

    // returning component viewf
    return (
        <>
            <Breadcrumb icon={icon} title={title} toggleSidebar={props.toggleSidebar} authenticate={props.authenticate} />
            <aside id="sidebar" className="sidebar hide-on-print">
                <i className="material-icons-round toggle-sidebar-btn" onClick={props.toggleSidebar}>arrow_circle_left</i>
                <div className="logo-container">
                    <Link to="/" className="logo" style={{
                        backgroundImage: `url("/logo.svg")`
                    }}>
                    </Link>
                    <div>{props.user.house ? text.reFormat(props.user.house.name) : applicationName}</div>
                </div>
                <ul className="sidebar-nav" id="sidebar-nav">
                    {renderMenu()}
                    <li className="nav-item">
                        <Link to="#" className="nav-link" onClick={() => props.authenticate("logout")}>
                            <Icon name="logout" type="round" />
                            <span>{translate("Logout")}</span>
                        </Link>
                    </li>
                </ul>
                <li className="version-container">
                    <span className="title">v</span>
                    <span className="version">1.1.0</span>
                </li>
            </aside>
        </>
    )

})

/* exporting component */
export default Sidebar