import { commonCondition, text } from '.';

// backend condition
const getBackendCondition = (name: string): object => {
    try {

        // remove name white space
        name = text.format(name)

        // user condition
        let condition = commonCondition()
        const conditionExpression = condition.$expr
        // console.log(conditionExpression)

        // day time
        const todayDate: number = new Date().setHours(0, 0, 0)

        switch (name) {

            // listing deleted data
            case "deleted":
                condition = { ...condition, visible: false }
                break

            // listing paid data
            case "paid":
                condition = {
                    ...condition,
                    $expr: {
                        $and: [
                            conditionExpression,
                            { $eq: ["$total_amount", "$paid_amount"] }
                        ]
                    }
                }
                break

            // listing unpaid data
            case "unpaid":
                condition = {
                    ...condition,
                    $expr: {
                        $and: [
                            conditionExpression,
                            { $eq: ["$paid_amount", 0] }
                        ]
                    }
                }
                break

            // listing partial paid data
            case "partial_paid":
                condition = {
                    ...condition,
                    $expr: {
                        $and: [
                            conditionExpression,
                            {
                                $and: [
                                    { $ne: ["$paid_amount", 0] },
                                    { $ne: ["$paid_amount", "$total_amount"] }
                                ]
                            }
                        ]
                    }
                }
                break

            // listing today data by date field
            case "of_today":
                condition = {
                    ...condition,
                    $expr: {
                        $and: [
                            conditionExpression,
                            { $gte: ["$date", todayDate] }
                        ]
                    }
                }
                break

            // subscription
            case "with_subscription":
            case "without_subscription":
                condition = {
                    ...condition,
                    $expr: {
                        $and: [
                            conditionExpression,
                            { [name.includes("without") ? "$lte" : "$gt"]: ["$days", 0] }
                        ]
                    }
                }
                break

            case "other":
            case "increase":
            case "decrease":
            case "sms_purchase":
            case "system_installation":
            case "monthly_subscription":
            case "server_payment":
            case "vendor_name_registration":
                condition = {
                    ...condition,
                    $expr: {
                        $and: [
                            conditionExpression,
                            { $eq: ["$type", name] }
                        ]
                    }
                }
                break

            // user account type
            case "admins":
            case "owners":
            case "employee":
                condition = {
                    ...condition,
                    $expr: {
                        $and: [
                            conditionExpression,
                            { $eq: ["$account_type", name === "admins" ? "limsapp" : name === "owners" ? "user" : name] }
                        ]
                    }
                }
                break

            // status
            case "active":
            case "rented":
            case "received":
            case "canceled":
            case "completed":
            case "available":
            case "incomplete":
            case "unavailable":
                condition = {
                    ...condition,
                    $expr: {
                        $and: [
                            conditionExpression,
                            { $eq: ["$status", name] }
                        ]
                    }
                }
                break

            // if no match
            default:
                condition = { ...condition }
                break
        }

        return condition

    } catch (error) {
        return { error: (error as Error).message }
    }
}

// exporting 
export default getBackendCondition