/* require dependencies */
import { lazy } from "react"
import { routerProps } from "../types"

// route
export type route = {
    path: string,
    guest: boolean,
    component: React.LazyExoticComponent<React.FunctionComponent<routerProps>>
}

/* array of routes */
const routes: route[] = [


    {
        path: "/",
        component: lazy(() => import("../pages/home/index")),
        guest: true
    },

    /* user routes (unauthenticated) */
    {
        path: "/login",
        component: lazy(() => import("../pages/user/login")),
        guest: true
    },

    // forgot password
    {
        path: "/forgot-password",
        component: lazy(() => import("../pages/user/forgot-password")),
        guest: true
    },

    // verification code
    {
        path: "/verification-code",
        component: lazy(() => import("../pages/user/verification-code")),
        guest: true
    },

    // change password
    {
        path: "/change-password",
        component: lazy(() => import("../pages/user/change-password")),
        guest: true
    },

    /* authenticated routes */

    // user form
    {
        path: "/user/form",
        component: lazy(() => import("../pages/user/form")),
        guest: false
    },

    // user list
    {
        path: "/user/list",
        component: lazy(() => import("../pages/user/list")),
        guest: false
    },

    // user view
    {
        path: "/user/view",
        component: lazy(() => import("../pages/user/view")),
        guest: false
    },

    // profile edit
    {
        path: "/profile/edit",
        component: lazy(() => import("../pages/user/form")),
        guest: false
    },

    // profile view
    {
        path: "/profile/view",
        component: lazy(() => import("../pages/user/view")),
        guest: false
    },

    // profile password
    {
        path: "/profile/password",
        component: lazy(() => import("../pages/user/password")),
        guest: false
    },

    // expense
    {
        path: "/expense/type-form",
        component: lazy(() => import("../pages/expense-type/form")),
        guest: false
    },
    {
        path: "/expense/type-list",
        component: lazy(() => import("../pages/expense-type/list")),
        guest: false
    },
    {
        path: "/expense/type-view",
        component: lazy(() => import("../pages/expense-type/view")),
        guest: false
    },
    {
        path: "/expense/form",
        component: lazy(() => import("../pages/expense/form")),
        guest: false
    },
    {
        path: "/expense/list",
        component: lazy(() => import("../pages/expense/list")),
        guest: false
    },
    {
        path: "/expense/view",
        component: lazy(() => import("../pages/expense/view")),
        guest: false
    },

    // tenant routes
    {
        path: "/tenant/form",
        component: lazy(() => import("../pages/tenant/form")),
        guest: false
    },
    {
        path: "/tenant/list",
        component: lazy(() => import("../pages/tenant/list")),
        guest: false
    },
    {
        path: "/tenant/view",
        component: lazy(() => import("../pages/tenant/view")),
        guest: false
    },

    // role routes
    {
        path: "/role/form",
        component: lazy(() => import("../pages/role/form")),
        guest: false
    },
    {
        path: "/role/list",
        component: lazy(() => import("../pages/role/list")),
        guest: false
    },
    {
        path: "/role/view",
        component: lazy(() => import("../pages/role/view")),
        guest: false
    },

    // house routes
    {
        path: "/house/form",
        guest: false,
        component: lazy(() => import("../pages/house/form"))
    },
    {
        path: "/house/list",
        guest: false,
        component: lazy(() => import("../pages/house/list"))
    },
    {
        path: "/house/view",
        guest: false,
        component: lazy(() => import("../pages/house/view"))
    },
    {
        path: "/house/data",
        guest: false,
        component: lazy(() => import("../pages/house/data"))
    },
    {
        path: "/house/settings",
        guest: false,
        component: lazy(() => import("../pages/house/settings"))
    },
    {
        path: "/payment/form",
        guest: false,
        component: lazy(() => import("../pages/payment/form"))
    },
    {
        path: "/payment/list",
        guest: false,
        component: lazy(() => import("../pages/payment/list"))
    },
    {
        path: "/payment/view",
        guest: false,
        component: lazy(() => import("../pages/payment/view"))
    },

    // room routes
    {
        path: "/room/form",
        guest: false,
        component: lazy(() => import("../pages/room/form"))
    },
    {
        path: "/room/list",
        guest: false,
        component: lazy(() => import("../pages/room/list"))
    },
    {
        path: "/room/view",
        guest: false,
        component: lazy(() => import("../pages/room/view"))
    },

    // lease routes
    {
        path: "/room/lease-form",
        guest: false,
        component: lazy(() => import("../pages/lease/form"))
    },
    {
        path: "/room/lease-list",
        guest: false,
        component: lazy(() => import("../pages/lease/list"))
    },
    {
        path: "/room/lease-view",
        guest: false,
        component: lazy(() => import("../pages/lease/view"))
    },

    // report routes
    {
        path: "/report/form",
        guest: false,
        component: lazy(() => import("../pages/report/form"))
    },
    {
        path: "/report/income-statement",
        guest: false,
        component: lazy(() => import("../pages/report/income-statement"))
    },

    // dashboard
    {
        path: "/dashboard",
        component: lazy(() => import("../pages/dashboard/dashboard")),
        guest: false
    },

    {
        path: "/",
        component: lazy(() => import("../pages/dashboard/dashboard")),
        guest: false
    },

    // general routes
    {
        path: "*",
        component: lazy(() => import("../pages/404/page-not-found")),
        guest: false
    },
    {
        path: "*",
        component: lazy(() => import("../pages/user/login")),
        guest: true
    },

]

export default routes