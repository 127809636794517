import { getInfo } from "../helpers"
import state from "./state"

// initial state
const initialState: state = {

    // main
    theme: getInfo("user", "settings")?.theme ? getInfo("user", "settings")?.theme : "auto",
    themeError: "",
    unMount: undefined,
    loading: false,
    authenticated: false,
    disabled: false,
    all: true,
    id: "",
    ids: [],
    edit: false,
    notification: "",
    searchKeyword: "",
    schema: "all",
    collection: "all",
    backendStatus: "restored",
    selected: [],
    propsCondition: {},
    description: "",
    descriptionError: "",
    date: "",
    dateError: "",
    totalAmount: "",
    totalAmountError: "",
    paidAmount: "",
    paidAmountError: "",
    location: "",
    locationError: "",
    status: "available",
    statusError: "",
    type: "",
    typeError: "",
    language: "english",
    languageError: "",

    // user
    account: "",
    accountError: "",
    password: "",
    passwordError: "",
    oldPassword: "",
    oldPasswordError: "",
    code: "",
    codeError: "",
    passwordConfirmation: "",
    passwordConfirmationError: "",
    userName: "",
    userNameError: "",
    phoneNumber: "",
    phoneNumberError: "",
    extraPhoneNumber: "",
    extraPhoneNumberError: "",
    fullName: "",
    fullNameError: "",
    accountType: "",
    accountTypeError: "",
    users: [],
    user: null,
    oldPhoneNumber: "",
    phoneNumberVerified: false,
    apiKey: "",
    apiKeyError: "",

    // listing
    page: 1,
    pages: 0,
    nextPage: 0,
    previousPage: 0,
    limit: 100,
    sort: "createdAt",
    order: 1,
    condition: "",
    limits: [],
    pageNumbers: [],
    list: [],
    select: {},
    fields: [],

    // role
    roles: [],
    role: null,

    // house
    houseNumber: "",
    houseNumberError: "",
    landlord: "",
    landlordError: "",
    houses: [],
    house: null,

    // tenant
    tenants: [],
    tenant: null,
    tenantName: "",
    tenantNameError: "",
    tenantId: "",
    tenantIdError: "",

    // room
    floorNumber: "",
    floorNumberError: "",
    roomNumber: "",
    roomNumberError: "",
    roomType: "",
    roomTypeError: "",
    pricePerMonth: "",
    pricePerMonthError: "",
    rooms: [],
    room: null,
    roomId: "",
    roomIdError: "",

    // room_booking(lease)
    moveInDate: "",
    moveInDateError: "",
    moveOutDate: "",
    moveOutDateError: "",
    originalMoveInDate: "",
    originalMoveInDateError: "",
    totalDays: "",
    totalDaysError: "",
    period: "",
    periodError: "",

    // payments
    payments: [],
    payment: null,

    // expense
    expenses: [],
    expense: null,
    expense_types: [],
    expense_type: null,
    expenseTypeName: "",
    expenseTypeNameError: "",
    expenseName: "",
    expenseNameError: "",
    expenseType: "",
    expenseTypeError: "",
    expenseTypeId: "",
    expenseTypeIdError: "",
    paidExpenses: 0,
    partialPaidExpenses: 0,
    unpaidExpenses: 0,

    name: "",
    nameError: "",
    region: "",
    regionError: "",
    street: "",
    streetError: "",
    fee: "",
    feeError: "",
    permissions: [],
    permissionsError: "",
    days: "",
    daysError: "",
    vendor: "",
    vendorError: "",
    visibleData: [],
    roleId: "",
    roleIdError: "",
    paymentType: "",
    paymentTypeError: "",
    houseId: "",
    houseIdError: "",
    houseName: "",
    houseNameError: "",
    title: "",
    room_lease: null,
    room_leases: [],
    roomLeaseId: "",
    roomLeaseIdError: "",
    userId: "",
    userIdError: "",
    year: new Date().getFullYear(),
    yearError: "",
    revenue: 0,
    leasePayments: 0,
    totalExpenses: 0,
    netIncome: 0,
    paymentMethods: [],
    currencyFormat: "0,0",
    currencyFormatError: "",
    totalPayments: 0,
    ruleName: "",
    ruleNameError: "",
    rules: [],
    opened: 0,
    rule: "",
    ruleError: "",
    report: null,
    reportType: "unMount",
    reportTypeError: "",
    viewDeletedReport: "no",
    dateTo: "",
    dateToError: "",
    leaseNumber: 0,
    offset: 0,
}

// exporting initial state
export default initialState