import { isAdmin, user } from "."


// permissions name
export type permissionName =
    "view_dashboard_menu" | "view_dashboard" | "restore_deleted" | "list_deleted" | "view_expense_menu" | "view_expense" | "create_expense" | "edit_expense" | "list_expense" | "delete_expense" | "view_expense_type" | "create_expense_type" | "edit_expense_type" | "list_expense_type" | "delete_expense_type" | "view_room_menu" | "view_room" | "create_room" | "edit_room" | "list_room" | "delete_room" | "view_room_lease_menu" | "view_room_lease" | "create_room_lease" | "edit_room_lease" | "list_room_lease" | "delete_room_lease" | "download_room_lease" | "print_room_lease" | "view_tenant_menu" | "view_tenant" | "create_tenant" | "edit_tenant" | "list_tenant" | "delete_tenant" | "view_role_menu" | "view_role" | "create_role" | "edit_role" | "list_role" | "delete_role" | "view_user_menu" | "view_user" | "create_user" | "edit_user" | "list_user" | "delete_user" | "view_message_menu" | "create_message" | "list_message" | "view_house_menu" | "view_house" | "create_house" | "edit_house" | "list_house" | "delete_house" | "view_payment_menu" | "view_payment" | "create_payment" | "edit_payment" | "list_payment" | "delete_payment" | "create_report" | "view_report_statistics" | "view_report_menu" | "switch_house" | "print_report" | "view_deleted_data_report" | "cancel_payment" | "view_income_statement" | "change_house_settings" | "download_report" | "view_house_data" | "reset_house" | "change_user_house_access"

// type
export type permission = {
    module: string
    permissions: permissionName[]
}

// permissions
export const permissions: permission[] = [
    {
        module: "role",
        permissions: ["view_role_menu", "view_role", "create_role", "edit_role", "delete_role", "list_role"]
    },

    {
        module: "tenant",
        permissions: ["view_tenant_menu", "view_tenant", "create_tenant", "edit_tenant", "delete_tenant", "list_tenant"]
    },

    {
        module: "expense",
        permissions: ["view_expense_menu", "view_expense", "create_expense", "edit_expense", "delete_expense", "list_expense"]
    },

    {
        module: "expense_type",
        permissions: ["view_expense_type", "create_expense_type", "edit_expense_type", "delete_expense_type", "list_expense_type"]
    },

    {
        module: "room_lease",
        permissions: ["view_room_lease_menu", "view_room_lease", "create_room_lease", "edit_room_lease", "delete_room_lease", "list_room_lease", "print_room_lease", "download_room_lease"]
    },
    {
        module: "room",
        permissions: ["view_room_menu", "view_room", "create_room", "edit_room", "delete_room", "list_room"]
    },

    {
        module: "user",
        permissions: ["view_user_menu", "view_user", "create_user", "edit_user", "delete_user", "list_user", "change_user_house_access"]
    },

    {
        module: "dashboard",
        permissions: ["view_dashboard_menu", "view_dashboard"]
    },

    {
        module: "message",
        permissions: ["view_message_menu", "create_message", "list_message"]
    },

    {
        module: "report",
        permissions: ["view_report_menu", "create_report", "view_report_statistics", "print_report", "view_deleted_data_report", "view_income_statement", "download_report"]
    },
    {
        module: "general",
        permissions: ["restore_deleted", "list_deleted"]
    },
    {
        module: "house",
        permissions: ["view_house_menu", "view_house", "create_house", "edit_house", "delete_house", "list_house", "switch_house", "change_house_settings", "view_house_data", "reset_house"]
    },

    {
        module: "payment",
        permissions: ["view_payment_menu", "view_payment", "create_payment", "edit_payment", "delete_payment", "cancel_payment", "list_payment"]
    }
]

/* function for checking user permission */
export function can(permission: permissionName): boolean {
    try {

        // check if its admin or not
        if (isAdmin && user) {
            if (user.role === null)
                return true
            else if (user.role.permissions.includes(permission))
                return true
            return false
        }
        else if (user && user.role.permissions.includes(permission))
            return true
        return false

    } catch (error) {
        console.log((error as Error).message)
        return false
    }
}