// english words
const englishWords: string[] = [
    "dashboard",
    "room",
    "new room",
    "new lease",
    "list rooms",
    "list leases",
    "tenant",
    "new tenant",
    "list tenants",
    "expense",
    "new expense",
    "list expenses",
    "new expense type",
    "list expense types",
    "user",
    "new user",
    "list users",
    "role",
    "new role",
    "list roles",
    "payment",
    "house",
    "houses",
    "new house",
    "house data",
    "list houses",
    "change house settings",
    "profile",
    "view profile",
    "edit profile",
    "change password",
    "login",
    "logout",
    "page not found",
    "oops! page not found",
    "sorry, but the page you are looking for is not found. please, make sure you have type the correct url and your have permission to view the resource.",
    "create",
    "required",
    "name",
    "enter name",
    "user (optional)",
    "enter username",
    "room number",
    "enter room number",
    "type",
    "select type",
    "monthly price",
    "enter price per month",
    "status",
    "available",
    "rented",
    "description",
    "enter description",
    "check in",
    "Period (Months)",
    "check out",
    "date",
    "total amount",
    "paid amount",
    "enter paid amount",
    "list rented",
    "list deleted",
    "list available",
    "ascending order",
    "descending order",
    "created time",
    "limit of",
    "limit by",
    "enter search keyword",
    "select all",
    "select",
    "options",
    "total",
    "edit",
    "view",
    "edit room",
    "update",
    "room information",
    "list room lease",
    "list room leases",
    "created by",
    "created date",
    "updated by",
    "updated date",
    "deleted",
    "deleted by",
    "deleted date",
    "delete room",
    "restore",
    "updated successfully",
    "active",
    "completed",
    "list active",
    "list completed",
    "cost",
    "print",
    "new room lease",
    "edit lease",
    "room lease information",
    "phone number",
    "enter phone number",
    "region",
    "select region",
    "location",
    "enter location",
    "street",
    "enter street",
    "edit tenant",
    "tenant information",
    "expense type",
    "enter expense type",
    "list of today",
    "list paid",
    "list unpaid",
    "list partial paid",
    "remain amount",
    "edit expense",
    "expense information",
    "edit expense type",
    "expense type information",
    "account type",
    "language",
    "username",
    "users",
    "list owners",
    "list admins",
    "list employee",
    "list assistance",
    "no",
    "yes",
    "edit user",
    "user information",
    "permissions",
    "View dashboard",
    "View dashboard menu",
    "view expense",
    "list expense",
    "Create expense",
    "delete expense",
    "view expense menu",
    "View expense type",
    "list expense type",
    "create expense type",
    "delete expense type",
    "restore deleted",
    "general",
    "view house",
    "edit house",
    "list house",
    "reset house",
    "create house",
    "delete house",
    "switch house",
    "view house menu",
    "view house data",
    "Change house settings",
    "view payment",
    "edit payment",
    "list payment",
    "delete payment",
    "cancel payment",
    "view payment menu",
    "view role",
    "edit role",
    "list role",
    "create role",
    "delete role",
    "view role menu",
    "view room",
    "list room",
    "create room",
    "view room menu",
    "room lease",
    "view room lease",
    "edit room lease",
    "print room lease",
    "create room lease",
    "delete room lease",
    "view room lease menu",
    "view tenant",
    "list tenant",
    "create tenant",
    "delete tenant",
    "view tenant menu",
    "view user",
    "list user",
    "create user",
    "view user menu",
    "role information",
    "create payment",
    "list other",
    "list payments",
    "list canceled",
    "list server payment",
    "list system installation",
    "list monthly subscription",
    "payment information",
    "cancel",
    "house number",
    "enter house number",
    "landlord",
    "enter landlord",
    "monthly fee (Optional)",
    "enter fee",
    "days",
    "enter days",
    "list with subscription",
    "list without subscription",
    "monthly fee",
    "house information",
    "switch in this house",
    "switch out of this house",
    "report",
    "new report",
    "create report",
    "income statement",
    "new income statement",
    "net income",
    "print report",
    "download report",
    "view report menu",
    "view income statement",
    "view report statistics",
    "view deleted data report",
    "decline",
    "accept",
    "created successfully",
    "updated successfully",
    "no room has been found",
    "room confirmation",
    "are you sure you want to delete this room?",
    "are you sure you want to delete these rooms?",
    "are you sure you want to restore this rooom?",
    "are you sure you want to restore these rooms?",
    "room has been deleted",
    "rooms have been deleted",
    "room has been restored",
    "rooms have been restored",
    "can't be less or equal to zero",
    "can't be less than total amount",
    "no room_lease has been found",
    "room lease confirmation",
    "are you sure you want to delete this room lease?",
    "are you sure you want to delete these room leases?",
    "are you sure you want to restore this room lease?",
    "are you sure you want to restore these room leases?",
    "room_lease has been deleted",
    "room_leases have been deleted",
    "room_lease has been restored",
    "room_leases have been restored",
    "no tenant has been found",
    "tenant confirmation",
    "are you sure you want to delete this tenant?",
    "are you sure you want to delete these tenants?",
    "are you sure you want to restore this tenant?",
    "are you sure you want to restore these tenants?",
    "tenant has been deleted",
    "tenants have been deleted",
    "tenant has been restored",
    "tenants have been restored",
    "no expense has been found",
    "expense confirmation",
    "are you sure you want to delete this expense?",
    "are you sure you want to delete these expenses?",
    "are you sure you want to restore this expense?",
    "are you sure you want to restore these expenses?",
    "expense has been deleted",
    "expenses have been deleted",
    "expense has been restored",
    "expenses have been restored",
    "no expense_type has been found",
    "expense type confirmation",
    "are you sure you want to delete this expense type?",
    "are you sure you want to delete these expense types?",
    "are you sure you want to restore this expense type?",
    "are you sure you want to restore these expense types?",
    "expense_type has been deleted",
    "expense_types have been deleted",
    "expense_type has been restored",
    "expense_types have been restored",
    "no user has been found",
    "user confirmation",
    "are you sure you want to delete this user?",
    "are you sure you want to delete these users?",
    "are you sure you want to restore this user?",
    "are you sure you want to restore these users?",
    "user has been deleted",
    "users have been deleted",
    "user has been restored",
    "users have been restored",
    "no role has been found",
    "role confirmation",
    "are you sure you want to delete this role?",
    "are you sure you want to delete these roles?",
    "are you sure you want to restore this role?",
    "are you sure you want to restore these roles?",
    "role has been deleted",
    "roles have been deleted",
    "role has been restored",
    "roles have been restored",
    "payment confirmation",
    "are you sure you want to cancel this payment?",
    "are you sure you want to cancel these payments?",
    "payment has been canceled",
    "payments have been canceled",
    "monthly subscription is required",
    "no house has been found",
    "house confirmation",
    "are you sure you want to delete this house?",
    "are you sure you want to delete these houses?",
    "are you sure you want to restore this house?",
    "are you sure you want to restore these houses?",
    "house has been deleted",
    "houses have been deleted",
    "house has been restored",
    "houses have been restored",
    "expenses report",
    "payments report",
    "room leases report",
    "rooms report",
    "tenants report",
    "deleted expenses report",
    "deleted payments report",
    "deleted room leases report",
    "deleted rooms report",
    "deleted tenants report",
    "old password",
    "enter old password",
    "old password must have atleast 8 characters",
    "new password must have atleast 8 characters",
    "password confirmation must have atleast 8 characters",
    "new password",
    "confirm new password",
    "password does not match",
    "enter new password",
    "old password is not correct",
    "welcome back!",
    "account",
    "enter username or phone number",
    "password",
    "enter password",
    "forgot password?",
    "account does not exist",
    "password is not correct",
    "password must have atleast 4 characters",
    "contact us",
    "already have an account",
    "send code",
    "failed to send verification code",
    "forgot password",
    "verification code has been sent to your phone number",
    "verification code",
    "code",
    "enter code sent to your phone number",
    "verify",
    "code must have 6 digits",
    "code is not correct",
    "data comparison",
    "summary",
    "a few seconds ago",
    "a minute ago",
    "2 minutes ago",
    "3 minutes ago",
    "4 minutes ago",
    "5 minutes ago",
    "6 minutes ago",
    "7 minutes ago",
    "8 minutes ago",
    "9 minutes ago",
    "10 minutes ago",
    "11 minutes ago",
    "12 minutes ago",
    "13 minutes ago",
    "14 minutes ago",
    "15 minutes ago",
    "16 minutes ago",
    "17 minutes ago",
    "18 minutes ago",
    "19 minutes ago",
    "20 minutes ago",
    "21 minutes ago",
    "22 minutes ago",
    "23 minutes ago",
    "24 minutes ago",
    "25 minutes ago",
    "26 minutes ago",
    "27 minutes ago",
    "28 minutes ago",
    "29 minutes ago",
    "30 minutes ago",
    "31 minutes ago",
    "32 minutes ago",
    "33 minutes ago",
    "34 minutes ago",
    "35 minutes ago",
    "36 minutes ago",
    "37 minutes ago",
    "38 minutes ago",
    "39 minutes ago",
    "40 minutes ago",
    "41 minutes ago",
    "42 minutes ago",
    "43 minutes ago",
    "44 minutes ago",
    "45 minutes ago",
    "46 minutes ago",
    "47 minutes ago",
    "48 minutes ago",
    "49 minutes ago",
    "50 minutes ago",
    "51 minutes ago",
    "52 minutes ago",
    "53 minutes ago",
    "54 minutes ago",
    "55 minutes ago",
    "56 minutes ago",
    "57 minutes ago",
    "58 minutes ago",
    "59 minutes ago",
    "an hour ago",
    "2 hours ago",
    "3 hours ago",
    "4 hours ago",
    "5 hours ago",
    "6 hours ago",
    "7 hours ago",
    "8 hours ago",
    "9 hours ago",
    "10 hours ago",
    "11 hours ago",
    "12 hours ago",
    "13 hours ago",
    "14 hours ago",
    "15 hours ago",
    "16 hours ago",
    "17 hours ago",
    "18 hours ago",
    "19 hours ago",
    "20 hours ago",
    "21 hours ago",
    "22 hours ago",
    "23 hours ago",
    "a day ago",
    "2 days ago",
    "3 days ago",
    "4 days ago",
    "5 days ago",
    "6 days ago",
    "7 days ago",
    "8 days ago",
    "9 days ago",
    "10 days ago",
    "11 days ago",
    "12 days ago",
    "13 days ago",
    "14 days ago",
    "15 days ago",
    "16 days ago",
    "17 days ago",
    "18 days ago",
    "19 days ago",
    "20 days ago",
    "21 days ago",
    "22 days ago",
    "23 days ago",
    "24 days ago",
    "25 days ago",
    "26 days ago",
    "27 days ago",
    "28 days ago",
    "29 days ago",
    "30 days ago",
    "a month ago",
    "2 months ago",
    "3 months ago",
    "4 months ago",
    "5 months ago",
    "6 months ago",
    "7 months ago",
    "8 months ago",
    "9 months ago",
    "10 months ago",
    "11 months ago",
    "12 months ago",
    "a year ago",
    "2 years ago",
    "3 years ago",
    "4 years ago",
    "5 years ago",
    "6 years ago",
    "7 years ago",
    "8 years ago",
    "9 years ago",
    "10 years ago",
    "data was created",
    "data was modified",
    "data was deleted temporary",
    "data Was deleted permanently",
    "true",
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "augost",
    "september",
    "october",
    "november",
    "december",
    "server payment",
    "n/a",
    "payment methods",
    "english",
    "language",
    "helpers",
    "normal curreny",
    "formated currency",
    "extra formated currency",
    "unpaid expenses",
    "swahili",
    "tenants",
    "rooms",
    "Room Leases",
    "Room Lease",
    "room_leases",
    "room_lease",
    "expenses",
    "payments",
    "available rooms",
    "rented rooms",
    "completed room leases",
    "active room leases",
    "statistics",
    "expenses statistics",
    "payments statistics",
    "room_leases statistics",
    "Room leases statistics",
    "rooms statistics",
    "tenants statistics",
    "delete",
    "report type",
    "landlord signature",
    "witness (name and signature)",
    "tenant signature",
    "rental lease for house/apartment/room/frame",
    "rules",
    "bank payments",
    "mobile payments",
]

// exporting 
export default englishWords