/* requiring dependencies */
import moment from "moment"
import { encryptionType, listView, stateKey } from "../types"
import translate from "./translator"
import { can } from "./permissions"
import numeral from "numeral"

// enviroment variables
const enviroment = process.env
const project_stage = enviroment.NODE_ENV

// server port
const port = 2610

// domain name
const domain: string = "https://limsapp.co/api"

/* creating and exporting backend server URL */
export const serverURL: string = project_stage === "development" ? `http://localhost:${port}` : `${domain}`

/* creating and exporting application name */
export const applicationName: string = window.location.hostname.includes("test") ? "TEST" : "Lims App"

// page title
export const setPageTitle = (title: string): void => { document.title = (title) }

/* creating and exporting text object formating(new) */
export const text = {
    format: (text: string): any => {
        if (text)
            return text.toLowerCase().replace(/ /g, "_")
        return text
    },
    reFormat: (text: string): string => {
        if (text)
            return text?.substring(0, 1).toUpperCase() + text?.substring(1)?.replace(/_/g, " ")
        return text
    }
}

// number fx
export const number = {
    reFormat: (number: string): number => {
        const value = numeral(number).value()
        if (typeof value === "number")
            return value
        else
            return 0
    },
    format: (number: number | string) => numeral(number).format("0,0")
}

// decryption function (new)
export function decrypt(data: encryptionType): any {
    try {
        const decoded = window.atob(data.payload)
        const decrypted = JSON.parse(decoded)
        // console.log(`decrypted payload: ${decrypted}`)

        return decrypted

    } catch (error) {
        console.log((error as Error).message)
        localStorage.clear()
        return { success: false, message: (error as Error).message }
    }
}

// encryption function (new)
export function encrypt(data: any): encryptionType {
    try {
        const payload = window.btoa(JSON.stringify(data))
        // console.log(`encrypted payload: ${payload}`)

        return { payload }
    } catch (error) {
        console.log((error as Error).message)
        localStorage.clear()
        return { payload: (error as Error).message }
    }
}

// local storage options
export const storage = {
    clear: () => localStorage.clear(),
    retrieve: (key: string) => {

        const payload: string | null = localStorage.getItem(key)

        // checking wether payload is available
        if (payload) {
            const data = decrypt({ payload: JSON.parse(payload) })
            return data
        }
        return payload
    },
    store: (key: string, data: any) => localStorage.setItem(key, JSON.stringify(encrypt(data).payload)), /* awali ilikuwa JSON.stringfy(data.payload) */
    remove: (key: string) => localStorage.removeItem(key)
}

// create and exporting function for getting specific local storage data
export const getInfo = (key: string, info?: string) => {

    // retrieving data from local storage
    const data = storage.retrieve(key)

    // verifying that data is available
    if (data)

        // verifying data information has been provided
        if (info?.trim())
            // return requested info
            return data[info]

        // data info is not provided
        else
            // returning all data information NOTE: same as storage.retrieve() function
            return data
    else
        return null

}

/* user */
export const user = getInfo("user", "")

export const isOwner: boolean = user && (user.account_type === "user") ? true : false
export const isUser: boolean = user && ((user.account_type === "employee")) ? true : false
export const isAdmin: boolean = user && ((user.account_type === "limsapp") /* && (user.role === null) */) ? true : false

/* backend api path */

/* date formating */
export const getDate = (date: any): string => {
    date = new Date(date)
    const formatedDate = date.toDateString()
    let hours = date.getHours()
    hours = hours <= 9 ? `0${hours}` : hours
    let minutes = date.getMinutes()
    minutes = minutes <= 9 ? `0${minutes}` : minutes
    return `${formatedDate} - ${hours}:${minutes}`
}

// format date from moment
export const formatDate = (date: any) => translate(moment(date).startOf("seconds").fromNow())

/* user account type */
export const accountTypes: string[] = ["limsapp", "user", "assistance", "employee"]

/* page not found */
export const pageNotFound: string = "/page-not-found"

// tenant list view (customer)
export const tenantListView: listView[] = [
    { name: "new report", icon: "remove_circle", link: "/report/form", visible: can("create_report") },
    { name: "new room", icon: "remove_circle", link: "/room/form", visible: can("create_room") },
    { name: "new room lease", icon: "remove_circle", link: "/room/lease-form", visible: can("create_room_lease") }
]

// userListView ()
export const userListView: listView[] = [
    { name: "list tenants", link: "/tenant/list", visible: can("list_tenant") },
    { name: "list roles", link: "/role/list", visible: can("list_role") },
    { name: "list users", link: "/user/list", visible: can("list_user") },
    { name: "list rooms", link: "/room/list", visible: can("list_room") },
    { name: "new report", icon: "remove_circle", link: "/report/form", visible: can("create_report") },
    { name: "income_statement", icon: "remove_circle", link: "/report/income-statement", visible: can("view_income_statement") }
]

/* user or house list on view page */
export const houseListView: listView[] = [
    { name: "dashboard", icon: "dashboard", link: "/dashboard", visible: can("view_dashboard") },
    { name: "house data", icon: "data", link: "/house/data", visible: can("view_house_data") },
    { name: "list rooms", link: "/room/list", visible: can("list_room") },
    { name: "settings", icon: "settings", link: "/house/settings", visible: can("change_house_settings") },
    { name: "new payment", icon: "add_card", link: "/payment/form", visible: can("create_payment") },
    { name: "list payments", link: "/payment/list", visible: can("list_payment") },
]

// no access
export const noAccess: string = `You have no access to view this page`

// house types (normal, tower, real estate)
export const houseTypes: string[] = [
    "normal", "apartment", "commercial"
]

// getting relative time
export const getRelativeTime = (date: any) => translate(moment(date).startOf("seconds").fromNow())

// invalid 
export const isInvalid = (value: any): boolean => isNaN(Number(value))

// booking orders (same as transport report)
export const leaseReports: stateKey[] = [
    "room_leases"
]

// report types
export const reportTypes: stateKey[] = [
    "tenants", "rooms", "room_leases", "payments", "expenses"
]

// payment types
export const paymentTypes: string[] = [
    "system_installation", "monthly_subscription", "server_payment", "other"
]

// dashboard collection
export const dashboardCollections: stateKey[] = [
    "houses", "payments", "users", "tenants", "roles", "rooms", "room_leases"
]

// years
export const years = (): number[] => {
    try {

        const generatedYears: number[] = []
        const currentYear: number = new Date().getFullYear()

        // i assume system start to operate at 2020, i might change later
        for (let year = 2023; year <= currentYear; year += 1)
            generatedYears.push(year)

        return generatedYears

    } catch (error) {
        console.log(`Years generation error: ${(error as Error).message}`)
        return [new Date().getFullYear()]
    }
}

// payment providers
export const paymentProviders: string[] = [
    "NMB Bank", "CRDB Bank", "Equity Bank", "NBC Bank", "M-PESA", "Tigopesa", "Halopesa", "AirtelMoney"
]

// graph data
export const getGraphData = (dataArray: any[]): number[] => {
    try {

        const numbers: number[] = []

        if (dataArray) {
            for (let index = 1; index <= 12; index += 1) {
                const data = dataArray.filter((data: any) => data._id.month === index)[0]
                let amount: number = 0

                if (data) {
                    if (data.total_amount)
                        amount = data.total_amount
                    else if (data.paid_amount)
                        amount = data.paid_amount
                    else if (data.total_document)
                        amount = data.total_document
                }
                if (amount)
                    numbers.push(amount)
                else
                    numbers.push(0)
            }
        }

        return numbers

    } catch (error) {
        console.log(`Graph data error: ${(error as Error).message}`)
        return []
    }
}

// room types
export const roomTypes: string[] = [
    "normal", "office", "apartment", "frame"
]

// room status
export const roomStatus: string[] = [
    "available", "rented"
]

// lease status
export const leaseStatus: string[] = [
    "active", "completed"
]

// application vendor contacts
export const vendorContacts = {
    limsapp: "+255620552390",
    other: "+255777672745"
}

// getting common condition function (new way implementation)
export function commonCondition(listAll?: boolean): any {
    try {

        const pathname: string = window.location.pathname
        const condition: object = { visible: true, $expr: {} }

        if (user) {

            const userId = user._id
            const houseId = user.house ? user.house._id : ""
            const houses = user.houses ? user.houses.map((house: string) => ({ $toObjectId: house })) : []

            // console.log(`condition: ${condition}`)
            // console.log(`userId: ${userId}`)
            // console.log(`houseId ${houseId}`)
            // console.log(`houses ${houses}`)

            // system adminstrator
            if (isAdmin && (user.role === null))
                return condition

            // house owners
            if (isOwner) {
                if ((pathname === "/") || (pathname === "/dashboard"))
                    return {
                        ...condition,
                        $expr: {
                            $or: [
                                { $in: ["$_id", [{ $toObjectId: houseId }]] },
                                { $in: ["$house", [{ $toObjectId: houseId }]] }
                            ]
                        }
                    }
                return {
                    ...condition,
                    $expr: {
                        $or: [
                            { $in: ["$user", [{ $toObjectId: userId }]] },
                            { $in: ["$house", [{ $toObjectId: houseId }]] }
                        ]
                    }
                }
            }

            // house employee
            if (isUser) {
                if (
                    listAll ||
                    (pathname === "/user/form") ||
                    (pathname === "/user/form") ||
                    (pathname === "/house/list") ||
                    (pathname === "/report/form") ||
                    (pathname === "/tenant/form")
                    // (pathname === "/room/list")
                )
                    return {
                        ...condition,
                        $expr: {
                            $or: [
                                { $in: ["$_id", [{ $toObjectId: houseId }]] },
                                { $in: ["$house", [{ $toObjectId: houseId }]] },
                            ]
                        }
                    }

                // user see only what he or she has created on the house
                return {
                    ...condition,
                    $expr: {
                        $and: [
                            { $in: ["$house", [{ $toObjectId: houseId }]] },
                            // { $in: ["$house", [{ $toObjectId: houseId }]] },
                        ]
                    }
                }
            }

            return {
                ...condition,
                $expr: {
                    $or: [
                        { $in: ["$_id", houses] },
                        { $in: ["$house", houses] },
                        { $in: ["$user", [{ $toObjectId: userId }]] },
                        { $in: ["$created_by", [{ $toObjectId: userId }]] },
                    ]
                }
            }
        }
        else
            return { noUser: true }

    } catch (error) {
        return { error }
    }
}

/* // getting common condition function (old lims)
export function commonCondition(listAll?: boolean): any {
    try {

        const pathname: string = window.location.pathname
        const condition: object = { visible: true, $expr: {} }

        if (user) {

            const userId = user._id
            const houseId = user.house ? user.house._id : ""
            // const roomId = user.room ? user.room._id : ""
            const houses = user.houses ? user.houses.map((house: string) => ({ $toObjectId: house })) : []
            // const rooms = user.rooms ? user.rooms.map((room: string) => ({ $toObjectId: room })) : []

            // console.log(`condition: ${condition}`)
            // console.log(`userId: ${userId}`)
            // console.log(`houseId ${houseId}`)
            // console.log(`houses ${houses}`)

            // system adminstrator
            if (isAdmin && (user.role === null))
                return condition

            // house owners
            if (isOwner) {
                if ((pathname === "/") || (pathname === "/dashboard"))
                    return {
                        ...condition,
                        $expr: {
                            $or: [
                                { $in: ["$house", [{ $toObjectId: houseId }]] }
                                // { $in: ["$room", [{ $toObjectId: roomId }]] }
                            ]
                        }
                    }
                return {
                    ...condition,
                    $expr: {
                        $or: [
                            { $in: ["$user", [{ $toObjectId: userId }]] },
                            { $in: ["$house", [{ $toObjectId: houseId }]] }
                            // { $in: ["$room", [{ $toObjectId: roomId }]] },
                        ]
                    }
                }
            }

            // house employee
            if (isUser) {
                if (
                    listAll ||
                    (pathname === "/user/form") ||
                    (pathname === "/user/form") ||
                    (pathname === "/house/list") ||
                    (pathname === "/report/form") ||
                    (pathname === "/tenant/form")
                    // (pathname === "/room/list")
                )
                    return {
                        ...condition,
                        $expr: {
                            $or: [
                                { $in: ["$_id", [{ $toObjectId: houseId }]] },
                                { $in: ["$house", [{ $toObjectId: houseId }]] },
                                // { $in: ["$room", [{ $toObjectId: roomId }]] },
                            ]
                        }
                    }

                // user see only what he or she has created on the house(branch)
                return {
                    ...condition,
                    $expr: {
                        $and: [
                            { $in: ["$house", [{ $toObjectId: houseId }]] },
                            { $in: ["$house", [{ $toObjectId: houseId }]] },
                            // { $in: ["room", [{ $toObjectId: roomId }]] },
                            // { $in: ["$room", [{ $toObjectId: roomId }]] },
                        ]
                    }
                }
            }

            return {
                ...condition,
                $expr: {
                    $or: [
                        { $in: ["$_id", houses] },
                        { $in: ["$house", houses] },
                        // { $in: ["$room", [{ $toObjectId: roomId }]] },
                        { $in: ["$user", [{ $toObjectId: userId }]] },
                        { $in: ["$created_by", [{ $toObjectId: userId }]] },
                    ]
                }
            }
        }
        else
            return { noUser: true }

    } catch (error) {
        return { error }
    }
} */