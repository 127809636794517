// dependencies
import { storage } from "."
import englishWords from "./englishwords"
import swahiliWords from "./swahiliWords"

// translator
const translate = (text: string): string => {
    try {

        if (text && (text.trim().length > 0) && (swahiliWords.length === englishWords.length)) {

            // retrieving user current language
            const currentLanguage = storage.retrieve("language")

            // remove special _ character on text
            let translatedWord: string = text.trim().replace(/_/g, " ").toLowerCase()

            const wordExistInEnglishWords: string | undefined = englishWords.find((word: string) => (word.trim().toLowerCase() === translatedWord))
            const wordExistInSwahiliWords: string | undefined = swahiliWords.find((word: string) => (word.trim().toLowerCase() === translatedWord))

            // translating from english to swahili
            if (wordExistInEnglishWords && (currentLanguage === "swahili"))
                translatedWord = swahiliWords[englishWords.indexOf(wordExistInEnglishWords)]

            // translating from swahili to english
            if (wordExistInSwahiliWords && (currentLanguage === "english"))
                translatedWord = englishWords[swahiliWords.indexOf(wordExistInSwahiliWords)]

            // returning translated text
            return translatedWord[0].toUpperCase() + translatedWord.toLowerCase()?.substring(1)

        }

        return text

    } catch (error) {
        console.log(`Translation error: ${(error as Error).message}`)
        return text
    }
}

// exporting translator
export default translate