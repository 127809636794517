/* dependencies */
import React from "react"
import translate from "../helpers/translator"

type notification = {
    message: string
}

/* notification memorized functional component */
const Notification: React.FunctionComponent<notification> = React.memo((props: notification) => (
    <div className="message">
        <div className="message-icon">
            <i className="material-icons-round">notifications_active</i>
        </div>
        <div className="message-body">
            <p>{translate(props.message)}</p>
        </div>
        {/* <div className="message-close js-messageClose">
            <i className="material-icons-round">close</i>
        </div> */}
    </div>
))

/* exporting component */
export default Notification