/* requiring dependencies */
import React from "react"
import state from "../hooks/state"
import { backendStatus, createOrUpdate, datalistSearch, dispatch, mount, readOrDelete, serverResponse, validation } from "../types"
import { commonCondition, getInfo, leaseStatus, paymentTypes, roomStatus, serverURL, storage, text } from '.'
import { can } from "./permissions"
import pluralize from "pluralize"
import getBackendCondition from "./backendCondition"


/* create application global variables */

class Application {

    // variable type definition
    public state: state
    public dispatch: dispatch
    public serverURL: string
    public headers: HeadersInit
    public user: any
    public onUpdate: { updated_by: string | null }
    public onCreate: { created_by: string | null, house: string | null }
    public condition: { _id: string }
    public successMessage: string
    public loading: boolean

    // constructor
    constructor(state: state, dispatch: dispatch) {
        this.state = state
        this.dispatch = dispatch
        this.serverURL = serverURL
        this.user = getInfo("user")
        this.headers = {
            "Content-Type": "application/json",
            "token": this.user ? this.user._id : ""
        }
        this.condition = { _id: this.state.id }
        this.onUpdate = this.user ? { updated_by: this.user._id } : { updated_by: null }
        this.onCreate = this.user ? { created_by: this.user._id, house: this.user.house ? this.user.house._id : null } : { created_by: null, house: null }
        this.successMessage = this.state.edit ? "updated successfully" : "created successfully"
        this.loading = this.state.loading || true
    }

    // function for handling application input change
    public handleInputChange = (event: React.ChangeEvent<HTMLElement>): void => {
        try {

            // get HTML element name and value from event target
            const { name, value }: any = event.target

            // update application state
            this.dispatch({ [name]: value })

        } catch (error) {
            this.dispatch({ notification: (error as Error).message })
        }
    }

    // function for POST (creating) and PUT (updating) resource(s) to the server
    public createOrUpdate = async (options: createOrUpdate): Promise<serverResponse> => {
        try {

            // enable loading if neccessary
            this.dispatch({ loading: options.loading })

            // make a POST or PUT request to the server
            let response: any = await (await fetch(`${this.serverURL}/${options.route}`, {
                method: options.method,
                mode: "cors",
                body: JSON.stringify(options.body),
                headers: this.headers
            })).json()

            // disable loading
            this.dispatch({ loading: false })

            // returning response (* later review)
            return response

        } catch (error) {

            // disable loading
            this.dispatch({ loading: false })
            return { success: false, message: (error as Error).message }
        }
    }

    // function for GET (reading) and DELETE (deleting) resource(S) to the server
    public readOrDelete = async (options: readOrDelete): Promise<serverResponse> => {
        try {

            // enable loading if neccessary
            this.dispatch({ loading: options.loading })
            this.dispatch({ disabled: options.disabled ? true : false })

            // make a GET or DELETE request to the server
            let response = await (await fetch(`${this.serverURL}/${options.route}?${options.parameters}`, {
                method: options.method,
                mode: "cors",
                headers: this.headers
            })).json()

            // disable loading
            this.dispatch({ loading: false })
            this.dispatch({ disabled: false })

            // returning response
            return response

        } catch (error) {

            // disable loading
            this.dispatch({ loading: false })
            this.dispatch({ disabled: false })

            return { success: false, message: (error as Error).message }
        }
    }

    // user authentication login and logout
    public authenticate = (action: "login" | "logout", data?: any) => {
        try {

            // checking authentication action
            if (action === 'login' && data) {

                // storing user data in local storage
                storage.store("user", data)

                // updating authenticated state to true
                this.dispatch({ authenticated: true })
                if (can("view_dashboard"))
                    window.location.href = "/"
                else
                    window.location.href = "/profile/view"
                // this.dispatch({ notification: "You have been logged in" })
            }
            else {
                storage.remove("user")
                this.dispatch({ authenticated: false })
                window.location.href = "/"
                // this.dispatch({ notification: "You have been logged out" })
            }

        } catch (error) {

            // remove user data from local storage
            storage.remove("user")
            this.dispatch({ notification: (error as Error).message })
        }
    }

    // function for retrivinb user from local storage and authenticating (next day inabidi iwe cross-checked)
    public retrieveUserAndAuthenticate = async (): Promise<void> => {
        try {

            // retrieving user
            const user = storage.retrieve("user")
            const language = storage.retrieve("language")

            // verifying that user daa exist in local storage
            if (user) {
                // update authenticated state to true
                storage.store("language", user.settings.language)
                const condition: string = JSON.stringify({ _id: user._id, visible: true })
                this.dispatch({ authenticated: true })
                const options: readOrDelete = {
                    method: "GET",
                    loading: false,
                    disabled: false,
                    route: "user/read",
                    parameters: `id=${condition}`
                }

                const response = await this.readOrDelete(options)

                if (response.success) {
                    storage.store("user", response.message)
                    storage.store("language", response.message?.settings.language)
                }
                else {
                    localStorage.clear()
                    window.location.reload()
                }

            }
            else {
                // updating authenticated state to false
                this.dispatch({ authenticated: false })
                if (!language)
                    storage.store("language", "english")
            }

        } catch (error) {

            // updating authenticated state to false
            this.dispatch({ authenticated: false })
            this.dispatch({ notification: (error as Error).message })
        }
    }

    // function for opening and closing sidebar
    public toggleSidebar = (): void => {
        try {

            // getting body document element
            const body = document.querySelector("body")

            // checking wether body is available
            if (body) {

                // check wether sidebar has toggle-sidebar class
                if (body.classList.contains("toggle-sidebar"))
                    // removing class ie opening sidebar
                    body.classList.remove("toggle-sidebar")

                else
                    // adding class ie: closing sidebar
                    body.classList.add("toggle-sidebar")
            }

        } catch (error) {
            this.dispatch({ notification: (error as Error).message })
        }
    }

    // autoclose sidebar on medium screen and below
    public closeSidebar = (): void => {
        try {
            if (window.screen.availWidth <= 1199)
                this.toggleSidebar()
        } catch (error) {
            this.dispatch({ notification: (error as Error).message })
        }
    }

    // showing and hiding component (ie modal or dialog: for now i will deal with the dialog)
    public toggleComponent(name: "modal" | "dialog"): void {
        try {

            // select component
            const component: HTMLElement | null = document.querySelector(`.${name}`)

            // checking wether component is selected or not
            if (component)
                // toggle show component class
                component.classList.toggle("show")

        } catch (error) {
            this.dispatch({ notification: (error as Error).message })
        }
    }

    // component unmounting
    public unMount = (): void => this.dispatch({ unMount: { ...this.state } })

    // load component data on mounting
    public mount = async (options: mount): Promise<serverResponse> => {
        try {

            this.dispatch({ fields: options.fields })
            this.dispatch({ select: options.select })
            this.dispatch({ schema: options.schema })
            this.dispatch({ collection: options.collection })

            // make api request
            const response: serverResponse = await this.readOrDelete({
                route: options.route,
                loading: true,
                disabled: false,
                parameters: options.parameters,
                method: "GET"
            })

            if (response.success) {

                if (options.route === "vsl/list-all") {
                    this.dispatch({ [options.collection]: response.message })
                    // console.log(options.collection)
                } else if (options.route === "vsl/list") {
                    // console.log(response.message.totalDocuments)
                    this.dispatch({ sort: options.sort })
                    this.dispatch({ order: options.order })
                    this.dispatch({ condition: options.condition })
                    this.dispatch({ limit: response.message.limit })
                    this.dispatch({ page: response.message.currentPaPge })
                    this.dispatch({ nextPage: response.message.nextPage })
                    this.dispatch({ pages: response.message.totalDocuments })
                    this.dispatch({ previousPage: response.message.previousPage })
                    this.dispatch({ [options.collection]: response.message.documents })
                    // console.log(response.message)

                    // pagination
                    this.pagination(response.message)
                }

                return response
            }
            this.dispatch({ condition: options.condition })
            this.dispatch({ notification: response.message })
            return response

        } catch (error) {

            // return response
            return { success: false, message: (error as Error).message }
        }
    }

    // searching data to the server
    public searchData = async (event: React.ChangeEvent<HTMLFormElement>): Promise<void> => {
        try {

            // prevent default form submit
            event.preventDefault()

            // checking wether keyword has been provided
            if (this.state.searchKeyword.trim() !== "") {

                // search deleted data
                const searchDeleted: object = this.state.condition === "deleted" ? { visible: false } : { visible: true }

                // parameters, condition and sort
                const condition: string = JSON.stringify({ ...commonCondition(), ...this.state.propsCondition, ...searchDeleted })
                // const sort: string = JSON.stringify({ createdAt: 1 })
                // const select: string = JSON.stringify(this.state.select)
                const parameters: string = `model=${this.state.schema}&keyword=${text.format(this.state.searchKeyword)}&condition=${condition}`

                // request options
                const options: readOrDelete = {
                    route: "vsl/search",
                    method: "GET",
                    parameters,
                    loading: true,
                    disabled: false
                }

                // making api request
                const response: serverResponse = await this.readOrDelete(options)

                // checking wether data have been found
                if (response.success) {
                    this.dispatch({ pageNumbers: [] })
                    this.dispatch({ [this.state.collection]: response.message })
                }
                else {
                    this.dispatch({ pageNumbers: [] })
                    this.dispatch({ [this.state.collection]: [] })
                    this.dispatch({ notification: response.message })
                }

                // this.dispatch({ ids: { ids: [] } })
            }

        } catch (error) {
            this.dispatch({ notification: (error as Error).message })
        }
    }

    // filtering data to backend
    public filterData = async (condition: string, order: 1 | -1, sort: string, limit: number): Promise<void> => {
        try {

            if (
                (this.state.condition !== condition) ||
                (this.state.order !== order) ||
                (this.state.sort !== sort) ||
                (this.state.limit !== limit)
            ) {

                const sortKey = sort === "created time" ? "createdAt" : ["region", "location", "street"].includes(sort) ? `address.${sort}` : sort.replace(/ /g, "_")

                // parameters. condition and sort
                const backendSort: string = JSON.stringify({ [sortKey]: order })
                const backendCondition: string = JSON.stringify({ ...getBackendCondition(condition), ...this.state.propsCondition })
                const select: string = JSON.stringify(this.state.select)
                const page: number = isNaN(this.state.page) ? 1 : Math.max(this.state.page, 1)
                const parameters: string = `model=${this.state.schema}&condition=${backendCondition}&order=${backendSort}&page=${page}&limit=${limit}&select=${select}`
                // console.log("Constructed Backend Condition:", backendCondition)
                // console.log("parameters:", parameters)
                // console.log("backend Sort:", backendSort)

                /* 
                    // Ensure page is a valid number, default to 1 if NaN
                    const page: number = isNaN(this.state.page) ? 1 : Math.max(this.state.page, 1)
                    const parameters: string = `model=tenant&condition=${condition}&sort=${sort}&select=${JSON.stringify(select)}&page=${page}&limit=${this.state.limit}` 
                 */

                // request options
                const options: readOrDelete = {
                    route: "vsl/list",
                    method: "GET",
                    loading: true,
                    disabled: false,
                    parameters
                }

                // making api request
                const response: serverResponse = await this.readOrDelete(options)

                if (response.success) {
                    // console.log(response.message)
                    this.dispatch({ sort: sort })
                    this.dispatch({ order: order })
                    this.dispatch({ condition: condition })
                    this.dispatch({ limit: limit })
                    this.dispatch({ page: response.message.currentPage })
                    this.dispatch({ nextPage: response.message.nextPage })
                    this.dispatch({ pages: response.message.totalDocuments })
                    this.dispatch({ previousPage: response.message.previousPage })
                    this.dispatch({ [this.state.collection]: response.message.documents })

                    // pagination
                    this.pagination(response.message)
                }
                else {
                    // console.log(response.message)
                    this.dispatch({ page: 1 })
                    this.dispatch({ pages: 1 })
                    this.dispatch({ limits: [] })
                    this.dispatch({ sort: sort })
                    this.dispatch({ nextPage: 0 })
                    this.dispatch({ order: order })
                    this.dispatch({ limit: limit })
                    this.dispatch({ previousPage: 0 })
                    this.dispatch({ pageNumbers: [] })
                    this.dispatch({ condition: condition })
                    this.dispatch({ [this.state.collection]: [] })
                    this.dispatch({ notification: response.message })
                }

                this.dispatch({ ids: [] })
            }

        } catch (error) {
            this.dispatch({ notification: (error as Error).message })
        }
    }

    // paginate list
    private pagination = (data: any): void => {

        // variables destructuring
        const { pages, limit, currentPage } = data

        // counter to ensure we create only 10 pages pagination
        const screeWidth: number = window.screen.availWidth
        const screenLimit: number = screeWidth > 992 ? 10 : 5

        // create page numbers according to screen size
        let pageNumbers: number[] = []

        if (((pages.length <= 10) && (screenLimit === 10)) || ((pages.length <= 5) && screenLimit === 5))
            pageNumbers = pages
        else {

            let counter: number = 0
            for (let index = currentPage; counter < screenLimit; index += 1) {
                if (pages.includes(index))
                    pageNumbers.push(index)
                counter += 1
            }

            if ((pageNumbers.length < 10) && (screeWidth > 992))
                pageNumbers = [...new Set([...this.state.pageNumbers, ...pageNumbers])]
            else if ((pageNumbers.length < 5) && (screeWidth <= 992))
                pageNumbers = [...new Set([...this.state.pageNumbers, ...pageNumbers])]

        }

        // create limits
        let limits: number[] = []
        for (let index = 0; index <= 1000; index += limit)
            limits.push(index)

        // remove first element in limit, because it is 0
        limits.shift()

        limits = [...new Set([...this.state.limits, ...limits])]

        if (pageNumbers.length > 0)
            this.dispatch({ pageNumbers })
        else
            this.dispatch({ pageNumbers })

        if (limits.length > 0)
            this.dispatch({ limits })
        else
            this.dispatch({ limits })

    }

    // load new page (paginate)
    public paginateData = async (page: number): Promise<void> => {
        if ((page >= 1) && (this.state.page !== page)) {

            const sortKey = this.state.sort === "created time" ? "createdAt" : ["region", "location", "street"].includes(this.state.sort) ? `address.${this.state.sort}` : this.state.sort.replace(/ /g, "_")
            const backendSort: string = JSON.stringify({ [sortKey]: this.state.order })
            const backendCondition: string = JSON.stringify({ ...getBackendCondition(this.state.condition), ...this.state.propsCondition })
            const select: string = JSON.stringify(this.state.select)
            const parameters: string = `model=${this.state.schema}&condition=${backendCondition}&order=${backendSort}&page=${page}&limit=${this.state.limit}&select=${select}`

            // request options
            const options: readOrDelete = {
                route: "vsl/list",
                method: "GET",
                loading: true,
                disabled: false,
                parameters
            }

            // making api request
            const response: serverResponse = await this.readOrDelete(options)

            if (response.success) {

                this.dispatch({ limit: response.message.limit })
                this.dispatch({ page: response.message.currentPage })
                this.dispatch({ nextPage: response.message.nextPage })
                this.dispatch({ pages: response.message.totalDocuments })
                this.dispatch({ previousPage: response.message.previousPage })
                this.dispatch({ [this.state.collection]: response.message.documents })

                // pagination
                this.pagination(response.message)
            }
            else {
                this.dispatch({ notification: response.message })
            }
            // this.dispatch({ ids: [] })

        }
    }

    // table list selection
    public selectList = (id?: string): void => {
        try {

            // checking if id has been provided
            if (id)
                // remove id
                if (this.state.ids.includes(id))
                    this.dispatch({ ids: this.state.ids.filter((listId: string) => listId !== id) })
                // add new id
                else
                    this.dispatch({ ids: [...this.state.ids, id] })
            else
                // deselect all
                if ((this.state.ids.length === this.state[this.state.collection].length) && (this.state.ids.length > 0))
                    this.dispatch({ ids: [] })

                // select all
                else
                    this.dispatch({ ids: this.state[this.state.collection].map((list: any) => list._id) })

        } catch (error) {
            this.dispatch({ notification: (error as Error).message })
        }
    }

    // open dialog on button click
    public openDialog = async (backendStatus: backendStatus): Promise<void> => {
        try {
            // opening confirmation dialog
            this.toggleComponent("dialog")

            // update state vendor status
            this.dispatch({ backendStatus })
            // console.log(backendStatus)

        } catch (error) {
            this.dispatch({ notification: (error as Error).message })
        }
    }

    // get model sort and condition
    public getSortOrCondition = (type: "sort" | "condition"): string[] => {

        let initialConditions: string[] = can("list_deleted") && (this.state.collection !== "payments") ? [`deleted`, this.state.collection] : [this.state.collection]
        let initialSorts: string[] = ["created time"]
        let conditions: string[] = []
        let sorts: string[] = []

        switch (this.state.collection) {

            // expense_type
            case "expense_types":
                conditions = [...initialConditions]
                sorts = [...initialSorts, "name"]
                break

            // expense
            case "expenses":
                conditions = [...initialConditions, "paid", "unpaid", "of today", "partial_paid"]
                sorts = [...initialSorts, "total amount", "paid amount", "date"]
                break

            // house
            case "houses":
                conditions = [...initialConditions, "with subscription", "without subscription"]
                sorts = [...initialSorts, "name", "phone number", "location", "street", "region", "days"]
                break

            // rooms
            case "rooms":
                conditions = [...initialConditions, ...roomStatus]
                sorts = [...initialSorts, "status", "type", "description"]
                break

            // lease
            case "room_leases":
                conditions = [...initialConditions, ...leaseStatus]
                sorts = [...initialSorts, "status"]
                break

            // tenants
            case "tenants":
                conditions = [...initialConditions]
                sorts = [...initialSorts, "name", "phone number", "location", "street", "region"]
                break

            // user
            case "users":
                conditions = [...initialConditions, "owners", "employee", "admins"]
                sorts = [...initialSorts, "username", "phone number", "account type"]
                break

            // payments
            case "payments":
                conditions = [...initialConditions, "active", "canceled", ...paymentTypes]
                sorts = [...initialSorts, "total amount", "status", "type"]
                break

            // roles
            case "roles":
                conditions = [...initialConditions]
                sorts = [...initialSorts, "name", "description"]
                break

        }

        if (type === "condition") {
            return [...new Set(conditions)]
        }
        return [...new Set(sorts)]
    }

    // updating backend status
    public updateBackendStatus = async (): Promise<void> => {
        try {

            // closing dialog
            this.toggleComponent("dialog")

            // creating request options
            const options: createOrUpdate = {
                route: "vsl/bulk-update",
                method: "PUT",
                loading: true,
                body: this.state.ids.map((id: string) => (
                    (this.state.backendStatus === "deleted") || (this.state.backendStatus === "restored")
                        ?
                        {
                            schema: this.state.schema,
                            condition: { _id: id },
                            newDocumentData: {
                                ...this.onUpdate,
                                visible: this.state.backendStatus === "deleted" ? false : true
                            }
                        }
                        :
                        {
                            schema: this.state.schema,
                            condition: { _id: id },
                            newDocumentData: {
                                ...this.onUpdate,
                                status: text.format(this.state.backendStatus)
                            }
                        }
                ))
            }
            // console.log(options.body)

            // making api request
            const response: serverResponse = await this.createOrUpdate(options)

            // checking wether the request succeded
            if (response.success) {
                // console.log(response.message)
                const { passedQueries, failedQueries } = response.message
                // console.log(passedQueries, failedQueries)

                if (failedQueries.length === 0) {

                    // creating new data array
                    let newList: any[] = []

                    // checking wether status is not "deleted"
                    if ((this.state.backendStatus !== "deleted") && (this.state.backendStatus !== "restored"))

                        // eslint-disable-next-line
                        this.state[this.state.collection].map((data: any) => {
                            // creating new data object
                            let newData = data

                            passedQueries.map((updatedData: any) => data._id === updatedData._id ? newData = updatedData : null)
                            // adding updated data to new data array
                            newList.push(newData)
                        })
                    else {
                        newList = this.state[this.state.collection].filter((data: any) => !passedQueries.some((deletedData: any) => data._id === deletedData._id))
                    }

                    this.dispatch({ [this.state.collection]: newList })
                    this.dispatch({ [this.state.schema]: passedQueries[0] })
                    this.dispatch({ notification: `${this.state.ids.length > 1 ? `${this.state.collection} have` : ` ${this.state.schema} has`} been ${this.state.backendStatus}` })
                    this.dispatch({ backendStatus: "restored" })

                    // if (this.state.ids.length > 1)
                    this.dispatch({ ids: [] })
                }

            }
            else
                this.dispatch({ notification: `Failed to update ${this.state.schema}` })

        } catch (error) {
            this.dispatch({ notification: (error as Error).message })
        }
    }

    // validation
    public validate = async (options: validation): Promise<void> => {
        try {

            if (this.state[options.errorKey].trim() === "") {
                this.dispatch({ [options.errorKey]: "validating" })

                const response: serverResponse = await this.readOrDelete({
                    route: "vsl/validate",
                    method: "GET",
                    loading: false,
                    disabled: true,
                    parameters: `model=${options.schema}&condition=${JSON.stringify({ ...options.condition, visible: true })}&validationType=${this.state.edit ? "onUpdate" : "onCreate"}&documentId=${this.state.id}`
                })
                // console.log(response)


                if (response.success) {
                    // console.log(response.message)
                    this.dispatch({ [options.errorKey]: "data already exist" })
                }
                else {
                    this.dispatch({ [options.errorKey]: "" })
                    this.dispatch({ disabled: false })
                }
            }

        } catch (error) {
            this.dispatch({ notification: (error as Error).message })
        }
    }

    // send sms

    // data list search
    public datalistSearch = async (event: React.ChangeEvent<HTMLInputElement>, options: datalistSearch): Promise<serverResponse> => {
        try {

            const keyword: string = event.target.value

            if (keyword.trim() !== "") {
                const schema: string = options.schema
                // const sort: string = JSON.stringify(options.sort)
                // const select: string = JSON.stringify(options.select)
                // const fields: string = JSON.stringify(options.fields)
                const condition: string = JSON.stringify(options.condition ? options.condition : commonCondition(true))
                const parameters: string = `model=${schema}&condition=${condition}&keyword=${keyword}`
                /* const parameters: string = `model=${schema}&condition=${condition}&select=${select}&sort=${sort}&fields=${fields}&keyword=${keyword}` */
                // console.log(condition)

                // request options
                const requestOptions: readOrDelete = {
                    parameters,
                    method: "GET",
                    loading: false,
                    disabled: true,
                    route: "vsl/search"
                }

                // console.log(requestOptions)
                const response: serverResponse = await this.readOrDelete(requestOptions)

                if (response.success) {
                    this.dispatch({ [pluralize(options.schema)]: response.message })
                    // console.log(response)
                }

                return response
            }

            return { success: false, message: "No value has been provided" }

        } catch (error) {
            return { success: false, message: (error as Error).message }
        }
    }

    // loadMore
    loadMore = () => this.dispatch({ offset: this.state.offset + this.state.limit })

}

/* exporting global variable class */
export default Application