/* require dependencies */
import { application } from "../types"
import { Switch, Route } from 'react-router-dom'
import routes, { route } from "."

/* routing function */
const routing = (application: application) => {
    return (
        <Switch>
            {
                routes.map((route: route, index: number) => {

                    // unprotected routes
                    if (route.guest && !application.state.authenticated)
                        return (
                            <Route
                                key={index}
                                path={route.path}
                                render={(props) => (<route.component {...props} application={application} />)}
                                exact
                            />
                        )

                    // protected routes
                    if (!route.guest && application.state.authenticated) {
                        return (
                            <Route
                                key={index}
                                path={route.path}
                                render={(props) => (<route.component {...props} application={application} />)}
                                exact
                            />
                        )
                    }

                    return null

                })
            }
        </Switch>
    )
}

export default routing