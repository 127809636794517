/* requiring dependencies */
import React from "react"
import { children } from "../types"
import { FloatingButton } from "./button"
import Notification from "./notification"
import Sidebar from "./sidebar"

export type auth = {
    children?: children
    authenticate(action: "logout"): void
    toggleSidebar(): void
    closeSidebar(): void
    unMount(): void
    message?: string
    user: any
}

/* guest (unauthenticated) */
export const GuestLayout: React.FunctionComponent<{ children: children, message: string }> = React.memo((props: { children: children, message: string }) => (
    <main className="main-out">
        {props.message.trim() ? <Notification message={props.message.toString()} /> : null}
        {props.children}
    </main>
))

/* authenticated */
export const AuthLayout: React.FunctionComponent<auth> = React.memo((props: auth) => (
    <>
        <Sidebar
            authenticate={props.authenticate}
            unMount={props.unMount}
            toggleSidebar={props.toggleSidebar}
            closeSidebar={props.closeSidebar}
            user={props.user}
        />
        <main className="main" id="main">
            {props.message ? <Notification message={props.message.toString()} /> : null}
            {props.children}
            <FloatingButton
                class="floating-button-menu"
                to="#"
                icon="menu"
                tooltip=""
                onClick={props.toggleSidebar}
            />
        </main>
    </>
))