/* dependencies */
import React from "react"
import { getInfo, text } from "../helpers"
import translate from "../helpers/translator"
import { Icon } from "./elements"

type breadcrumb = {
    icon: string
    title: string
    toggleSidebar(): void
    authenticate(action: "logout"): void
}

/* breadcrumb memorized functional component */
const Breadcrumb: React.FunctionComponent<breadcrumb> = React.memo((props: breadcrumb) => (
    <div className="breadcrumb hide-on-print">
        <div className="section" onClick={props.toggleSidebar}>
            <Icon name={props.icon} type="round" />
            <span className="title">{translate(props.title)}</span>
        </div>
        <div className="section right-section" onClick={() => props.authenticate("logout")}>
            <Icon name={"alternate_email"} type="round" />
            <span className="title">{text.reFormat(getInfo("user", "username"))} | {translate("logout")}</span>
        </div>
    </div>
))

/* exporting component */
export default Breadcrumb